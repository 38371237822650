import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Leveling } from "../../assets/svg/leveling.svg";
import { ReactComponent as MirrorLeft } from "../../assets/svg/mirrorLeft.svg";
import { ReactComponent as MirrorRight } from "../../assets/svg/mirrorRight.svg";

import {
  sendRedrawSimpleEvent,
  sendSelectObjectEvent,
  sendUnselectEvent,
} from "../../Helpers/Events";
import { actionsState as projectState } from "../../Redux/project";
import { DistanceButton } from "../BluetoothRoulette/DistanceButton";
import { distance } from "../BluetoothRoulette/utils/distance";
import { useShortcuts } from "../Features/Shortcuts";
import { EstimateInfo, ObjectList } from "../Features/SideMenu";
import MaterialSelect from "../MaterialSelect";
import ModalWrapper from "../ModalWrapper";
import { Select } from "../UI";
import { checkRightDirectionWall, getWallCycle } from "../Utils";
import ObjectInfoBlock from "../widgets/ObjectInfoBlock";
import SizeBlock from "../widgets/SizeBlock";
import { ShowWall } from "./Components/ShowWall";
import { getOptionsFillWallForSelect } from "./utils/getOptionsFillWallForSelect";

const optionsFillWall = getOptionsFillWallForSelect();

const BWallInfo = ({ plan, wall }) => {
  const dispatch = useDispatch();
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const planeEdit = useSelector((state) => state.project.planeEdit);
  const mode = useSelector((state) => state.project.mode);
  const canvasSelector = planeEdit ? "#plan" : "#scene";
  const bluetoothStatus = useSelector(
    (store) => store.project.devices.bluetooth.status
  );
  const [materialWnd, setMaterialWnd] = useState(false);
  const [distanceButton, setDistanceButton] = useState({
    property: { depth: false, length: true, height: false, innerDepth: false },
    action: handlerLength,
  });

  const update = () => {
    setLastUpdate(Date.now());
  };

  const _setMaterial = (m) => {
    wall.materialRGB.rgbColor = false;
    wall.material = m;
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  const _setMaterialRgbColor = (rgb) => {
    wall.materialRGB.rgbColor = true;
    wall.materialRGB.rgb = rgb;
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  const toggleLRBuild = () => {
    wall.mainLink.lrBuild = wall.mainLink.lrBuild === "left" ? "right" : "left";
    plan.changeWallLRBuild(wall.mainLink, true);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  const toggleVisibility = () => {
    if (mode === "union") {
      wall.isShow = !wall.isShow;
      wall.ignoreAutoDisclosure = !wall.ignoreAutoDisclosure;
    } else {
      wall.showModule = !wall.showModule;
    }
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  function handlerDepth(value) {
    wall.mainLink.depth = value;
    plan.changeWallLRBuild(wall.mainLink);
    plan.moveWallColumns(wall);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  }

  const handlerInnerDepth = (value) => {
    wall.mainLink.innerDepth = value;
    plan.changeWallLRBuild(wall.mainLink);
    plan.moveWallColumns(wall);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  function handlerLength(value) {
    if (value > 5) {
      if (!checkRightDirectionWall(plan.cycles, wall.mainLink)) {
        for (let i = 0; i < plan.links.length; i++) {
          if (wall.mainLink === plan.links[i]) {
            const oldA = {
              x: plan.links[i].a.x,
              y: plan.links[i].a.y,
            };
            const oldB = {
              x: plan.links[i].b.x,
              y: plan.links[i].b.y,
            };
            plan.links[i].a.set(oldB.x, oldB.y);
            plan.links[i].b.set(oldA.x, oldA.y);
            if (plan.links[i].lrBuild === "right") {
              plan.links[i].lrBuild = "left";
            } else {
              plan.links[i].lrBuild = "right";
            }
            break;
          }
        }
      }
      wall.mainLink.length = value;
      plan.changeWallLRBuild(wall.mainLink);
      sendRedrawSimpleEvent(document.querySelector(canvasSelector));
      plan.setActionUndo({ type: "plan" });
    }
    update();
  }

  const handlerHeight = (value) => {
    wall.mainLink.height = value;
    update();
  };

  const toggleWallType = (type) => {
    switch (type) {
      case "Arc":
        wall.isArc = !wall.isArc;
        wall.isBezier = false;
        break;
      case "Bezier":
        wall.isBezier = !wall.isBezier;
        wall.isArc = false;
        break;
      default:
        wall.isArc = false;
        wall.isBezier = false;
        break;
    }
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
    update();
  };

  const handleArcRadiusLine = (val) => {
    wall.arcRadiusLine = val;
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    update();
  };
  const handleArcRadiusLineTune = (val) => {
    wall.arcRadiusLine = val;
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    update();
  };

  const handleArcRadius = (val) => {
    wall.arcRadius = val;
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    update();
  };

  const handleArcLength = () => {
    wall.arcLength = !wall.arcLength;
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    update();
  };

  const handlerDrawMaterial = (option) => {
    wall.planMaterial = option.id;
    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    update();
  };

  const _addHole = () => {
    const wallObjects = wall.addObject();
    sendSelectObjectEvent(
      document.querySelector(canvasSelector),
      wallObjects[wallObjects.length - 1],
      wall
    );
    plan.setActionUndo({ type: "plan" });
  };

  const addObjectOnWall = () => {
    const wallObjects = wall.addObjectOnWall();
    const onWallObjects = wallObjects.filter(
      (object) => object.isHole === undefined
    );
    sendSelectObjectEvent(
      document.querySelector(canvasSelector),
      onWallObjects[onWallObjects.length - 1],
      wall
    );
    plan.setActionUndo({ type: "plan" });
  };

  const close = () => {
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const remove = () => {
    plan.removeWall(wall);
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const setWallHeightForCycle = () => {
    const cycle = getWallCycle(wall, plan.cycles);
    cycle._links.forEach((link) => {
      link.height = wall.mainLink.height;
    });
  };

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  const wallCycles = getWallCycle(wall, plan.cycles);

  useShortcuts({ onDelete: remove });

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  return (
    <ModalWrapper
      isSideMenu={true}
      title={"Настройки стены"}
      onDelete={() => remove()}
      onClose={() => close()}
    >
      <div className="modal-body">
        <div className={"size-block"}>
          <div className="btn btn-icon btn-onestyle" onClick={toggleLRBuild}>
            {wall.mainLink.lrBuild === "left" && (
              <MirrorLeft className="size-block__btn" />
            )}
            {wall.mainLink.lrBuild === "right" && (
              <MirrorRight className="size-block__btn" />
            )}
            Построение
          </div>
          <ShowWall wall={wall} toggleVisibility={toggleVisibility} />
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>
            Внешняя толщина
          </h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={wall.mainLink.depth}
            onChange={handlerDepth}
            min={1}
            max={1000}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"depth"}
              action={handlerDepth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>
            Внутренняя толщина
          </h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={wall.mainLink.innerDepth}
            onChange={handlerInnerDepth}
            min={0}
            max={1000}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"innerDepth"}
              action={handlerInnerDepth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>Длина</h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={wall.mainLink.length}
            onChange={handlerLength}
            min={5}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"length"}
              action={handlerLength}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>Высота</h2>
          {wallCycles && (
            <Leveling
              className="size-block__btn"
              onClick={setWallHeightForCycle}
            />
          )}
          <SizeBlock
            value={wall.mainLink.height}
            onChange={handlerHeight}
            min={5}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"height"}
              action={handlerHeight}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <br />
        <Select
          options={optionsFillWall}
          value={optionsFillWall.find(
            (option) => option.value === wall.planMaterial
          )}
          onChange={(option) => handlerDrawMaterial(option)}
        />
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Кривые Безье</div>
          <div className={"options-title__option"}>
            <div
              className={
                wall.isBezier
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => toggleWallType("Bezier")}
            >
              &nbsp;
            </div>
          </div>
        </div>

        <div className="materials-block">
          <div className="options-title">Материал стены</div>
          <div className="material-select">
            {!wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  backgroundImage: "url(" + wall.material.userData.PICT + ")",
                  cursor: "pointer",
                }}
              >
                <span>{wall.material.userData.NAME}</span>
              </div>
            )}
            {wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  background:
                    "rgba(" +
                    wall.materialRGB.rgb.r +
                    "," +
                    wall.materialRGB.rgb.g +
                    "," +
                    wall.materialRGB.rgb.b +
                    "," +
                    wall.materialRGB.rgb.a +
                    ")",
                  cursor: "pointer",
                }}
              >
                <span>RGB цвет</span>
              </div>
            )}
          </div>
          {materialWnd === true && (
            <MaterialSelect
              onClose={() => setMaterialWnd(false)}
              active={wall.material}
              list={window.materials.wall}
              setMaterial={_setMaterial}
              title="Материал стены"
              rgbColor={true}
              _setRgbColor={_setMaterialRgbColor}
              activeRGB={wall.materialRGB.rgbColor}
              colorDef={wall.materialRGB.rgb}
            />
          )}
        </div>

        {planeEdit && (
          <div className="block">
            <div className="options-title">Проемы и объекты</div>
            <div className="wall-objects">
              <div className="wall-objects-btn">
                <div
                  className="btn btn-icon"
                  onClick={() => {
                    _addHole();
                    // close()
                  }}
                >
                  Добавить проем
                </div>
                <div
                  className="btn btn-icon"
                  onClick={() => {
                    addObjectOnWall();
                    // close()
                  }}
                >
                  Добавить объект
                </div>
              </div>
              <ObjectList
                plan={plan}
                canvasSelector={canvasSelector}
                parent={wall}
                objects={wall?.objects}
              />
            </div>
          </div>
        )}

        <ObjectInfoBlock obj={wall} />
        <EstimateInfo object={wall} />
      </div>
    </ModalWrapper>
  );
};

export default BWallInfo;
