export const collectPaths = (mainPoints, parallelPoints) => {
  const sortedArray = (array) => {
    const lastConnectIndex = array.lastIndexOf("connect");
    const lastGapIndex = array.lastIndexOf("gap");
    if (lastConnectIndex === -1 && lastGapIndex === -1) return array;

    const lastBreakIndex = Math.max(lastConnectIndex, lastGapIndex);

    const afterLastBreak = array.slice(lastBreakIndex + 1);
    const beforeLastBreak = array.slice(0, lastBreakIndex + 1);
    return [array[lastBreakIndex], ...afterLastBreak, ...beforeLastBreak];
  };

  const splitSegments = (points) => {
    const segments = [];
    let currentSegment = [];
    let startType = null;

    points.forEach((point) => {
      if (point === "connect" || point === "gap") {
        if (currentSegment.length > 0) {
          segments.push({
            segment: currentSegment,
            start: startType,
            end: point,
          });
          currentSegment = [];
        }
        startType = point;
      } else {
        currentSegment.push(point);
      }
    });

    if (currentSegment.length > 0) {
      segments.push({ segment: currentSegment, start: startType, end: null });
    }

    return segments;
  };

  const mainSegments = splitSegments(sortedArray(mainPoints));
  const parallelSegments = splitSegments(sortedArray(parallelPoints));

  const paths = [];
  mainSegments.forEach((main, index) => {
    if (main.start === null && main.end === null) {
      paths.push([...main.segment, main.segment[0]]);
      paths.push([
        ...parallelSegments[index].segment,
        parallelSegments[index].segment[0],
      ]);
    } else if (main.start === "connect" && main.end === "connect") {
      const path = [
        ...main.segment,
        ...parallelSegments[index].segment.reverse(),
        main.segment[0],
      ];
      paths.push(path);
    } else if (main.start === "connect" && main.end === "gap") {
      const path = [
        ...main.segment.reverse(),
        ...parallelSegments[index].segment,
      ];
      paths.push(path);
    } else if (main.start === "gap" && main.end === "connect") {
      const path = [
        ...main.segment,
        ...parallelSegments[index].segment.reverse(),
      ];
      paths.push(path);
    } else if (main.start === "gap" && main.end === "gap") {
      paths.push(main.segment);
      paths.push(parallelSegments[index].segment);
    }
  });

  const roundToPrecision = (value, precision) => {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  };

  const filterPaths = (paths, precision) => {
    return paths.filter((path) => {
      if (path.some((p) => p === undefined)) return false;

      const uniquePoints = new Set(
        path.map(
          (point) =>
            `${roundToPrecision(point.x, precision)},${roundToPrecision(
              point.y,
              precision
            )}`
        )
      );
      return uniquePoints.size > 2;
    });
  };

  const precision = 5;
  return filterPaths(paths, precision);
};
