import isEqual from "lodash/isEqual";

export const checkSameColorWall = (walls) => {
  if (walls.length === 1) {
    return true;
  }
  const materialRGB = {
    rgbColor: walls[0].materialRGB.rgbColor,
    rgb: {
      a: walls[0].materialRGB.rgb.a,
      r: walls[0].materialRGB.rgb.r,
      g: walls[0].materialRGB.rgb.g,
      b: walls[0].materialRGB.rgb.b,
    },
  };
  for (const wall of walls) {
    if (!isEqual(wall.materialRGB, materialRGB)) {
      return false;
    }
  }
  return true;
};
