import { useSelector } from "react-redux";

export const ShowWall = ({ wall, toggleVisibility }) => {
  const mode = useSelector((state) => state.project.mode);
  return (
    <div
      className="btn btn-icon btn-onestyle"
      style={{ marginLeft: "5px" }}
      onClick={toggleVisibility}
    >
      {getText(mode, wall)}
    </div>
  );
};

const getText = (mode, wall) => {
  if (mode === "union") {
    return wall.isShow ? "Скрыть" : "Показать";
  }
  return wall.showModule ? "Скрыть 3D" : "Показать 3D";
};
