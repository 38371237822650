import * as THREE from "three";
import CSG from "../../../Helpers/Three-CSG";

const extrudeSettings = (height, segments) => {
    return (
        {
            curveSegments: segments,
            steps: 1,
            depth: height,
            bevelOffset: 0.0035,
            bevelSize: 0,
            bevelThickness: 0,
        })
}

export const subtractHoleOnWall = (wall, mesh, meshGeometry, segments) => {
    let wallWithHoles;
    let holes = [];

    const objects = wall?.polygons3d?.holes || wall.objects.filter((object) => (object?.isHole || object?.isWindow || object?.isDoor) && !object?.isHidden);

    objects.forEach((hole) => {
        if (wall.isBezier) {
            const holeBezier = new THREE.Shape();

            const inline = [...hole.bezierIn].reverse();
            const outline = hole.bezierOut;
            const height = hole.height / 1000;
            const heightFromFloor = (hole.heightFromFloor + 1) / 1000;

            const bezierInStart = inline[0].points[0];
            const bezierInEnd = inline[inline.length - 1].points[3];

            holeBezier.moveTo(bezierInStart.x / 1000, bezierInStart.y / 1000);

            outline.forEach(point => {
                holeBezier.lineTo(point.points[0].x / 1000, point.points[0].y / 1000);
                holeBezier.bezierCurveTo(
                    point.points[1].x / 1000,
                    point.points[1].y / 1000,
                    point.points[2].x / 1000,
                    point.points[2].y / 1000,
                    point.points[3].x / 1000,
                    point.points[3].y / 1000);
            })

            holeBezier.lineTo(bezierInEnd.x / 1000, bezierInEnd.y / 1000);

            inline.forEach(point => {
                holeBezier.lineTo(point.points[3].x / 1000, point.points[3].y / 1000);
                holeBezier.bezierCurveTo(
                    point.points[2].x / 1000,
                    point.points[2].y / 1000,
                    point.points[1].x / 1000,
                    point.points[1].y / 1000,
                    point.points[0].x / 1000,
                    point.points[0].y / 1000);
            })

            const geometry = new THREE.ExtrudeGeometry(holeBezier, extrudeSettings(height, segments));
            const holeInWall = new THREE.Mesh(geometry);

            holeInWall.position.add(new THREE.Vector3(0, 0, heightFromFloor));
            holeInWall.updateMatrix();

            holes.push(CSG.fromMesh(holeInWall));
        } else {
            if (hole?.isCircleHole) {
                const holeShape = new THREE.Shape();
                const width = hole.width / 1000;
                const heightFromFloor = (hole.heightFromFloor + 1) / 1000 + (width / 2);

                const a = hole.rA1Inner;
                const b = hole.rB1Inner;

                const centerX = (a.x + b.x) / 2000;
                const centerY = (a.y + b.y) / 2000;
                const angle = Math.atan2(b.y - a.y, b.x - a.x);

                holeShape.moveTo(centerX, centerY);
                holeShape.absarc(centerX, centerY, width / 2, 0, 2 * Math.PI, false);

                const geometry = new THREE.ExtrudeGeometry(holeShape, extrudeSettings((hole.depth + 1) / 1000, segments));
                const holeInWall = new THREE.Mesh(geometry);

                holeInWall.geometry.translate(-centerX, -centerY, -0.001);
                holeInWall.rotateX(Math.PI / 2);
                holeInWall.rotateY(angle);
                holeInWall.position.add(new THREE.Vector3(centerX, centerY, heightFromFloor));
                holeInWall.updateMatrix();

                holes.push(CSG.fromMesh(holeInWall));

            } else {
                const holeShape = new THREE.Shape();
                const height = hole.height / 1000;
                const heightFromFloor = (hole.heightFromFloor + 1) / 1000;

                let a = hole.rA13d;
                let b = hole.rB13d;
                let c = hole.rB23d;
                let d = hole.rA23d;

                if (hole?.isHole) {
                    a = hole.rA1Inner;
                    b = hole.rB1Inner;
                    c = hole.rB2Inner
                    d = hole.rA2Inner;
                }

                holeShape.moveTo(a.x / 1000, a.y / 1000);
                holeShape.lineTo(b.x / 1000, b.y / 1000);
                holeShape.lineTo(c.x / 1000, c.y / 1000);
                holeShape.lineTo(d.x / 1000, d.y / 1000);
                holeShape.lineTo(a.x / 1000, a.y / 1000);

                const geometry = new THREE.ExtrudeGeometry(holeShape, extrudeSettings(height, segments));
                const holeInWall = new THREE.Mesh(geometry);

                holeInWall.position.add(new THREE.Vector3(0, 0, heightFromFloor));
                holeInWall.updateMatrix();

                holes.push(CSG.fromMesh(holeInWall));
            }
        }
    })

    if (holes.length > 0) {
        let booleanResult;
        holes.forEach((hole) => {
            wallWithHoles = booleanResult ? CSG.fromMesh(booleanResult).subtract(hole) : CSG.fromMesh(mesh).subtract(hole);
            booleanResult = CSG.toMesh(wallWithHoles, meshGeometry, mesh.matrix, mesh.material);
        })

        return booleanResult;
    }

    return mesh;
}
