import { TrueColor } from "@tarikjabiri/dxf";
import { Bezier } from "../../../../../Helpers/Bezier";
import { checkSameColorWall } from "./checkSameColorWall";
import { collectPaths } from "./collectPaths";
import { drawObject } from "./drawObject";
import { squaredDistance } from "./geometry";
import { getWallColor } from "./getWallColor";
import { getNextIndex, settingsDxf } from "./index";
import { drawHatches, drawLine, drawPolylines, drawSpline } from "./primitives";

const getWallParams = (walls, currentPoint, nextPoint) => {
  let mainPoint = null;
  let parallelPoint = null;
  let nextMainPoint = null;
  let nextParallelPoint = null;
  let currentWall = null;
  let isReverse = false;
  let objects = [];
  let isInvisible = false;

  walls.forEach((wall) => {
    if (
      wall.innerLink.a.x === currentPoint.x &&
      wall.innerLink.a.y === currentPoint.y &&
      wall.innerLink.b.x === nextPoint.x &&
      wall.innerLink.b.y === nextPoint.y
    ) {
      currentWall = wall;
      isReverse = wall.mainLink.lrBuild === "right";
      mainPoint = wall.innerLink.a;
      parallelPoint = wall.parallelLink.a;
      nextMainPoint = wall.innerLink.b;
      nextParallelPoint = wall.parallelLink.b;
      isInvisible = !wall.isShow;
      objects = [...wall.objects].sort((a, b) => {
        return (
          squaredDistance(mainPoint, a.objA) -
          squaredDistance(mainPoint, b.objA)
        );
      });
    }
    if (
      wall.innerLink.b.x === currentPoint.x &&
      wall.innerLink.b.y === currentPoint.y &&
      wall.innerLink.a.x === nextPoint.x &&
      wall.innerLink.a.y === nextPoint.y
    ) {
      currentWall = wall;
      isReverse = wall.mainLink.lrBuild === "left";
      mainPoint = wall.innerLink.b;
      parallelPoint = wall.parallelLink.b;
      nextMainPoint = wall.innerLink.a;
      nextParallelPoint = wall.parallelLink.a;
      isInvisible =
        currentWall?.connectedNodes &&
        currentWall?.connectedNodes?.length !== 0;
      objects = [...wall.objects]
        .sort((a, b) => {
          return (
            squaredDistance(mainPoint, a.objB) -
            squaredDistance(mainPoint, b.objB)
          );
        })
        .map((object) => ({
          ...object,
          rB1Inner: object.rA1Inner,
          rA1Inner: object.rB1Inner,
          rB2Inner: object.rA2Inner,
          rA2Inner: object.rB2Inner,
          ...(object?.rA13d ? { rB13d: object?.rA13d } : {}),
          ...(object?.rB13d ? { rA13d: object?.rB13d } : {}),
          ...(object?.rA23d ? { rB23d: object?.rA23d } : {}),
          ...(object?.rB23d ? { rA23d: object?.rB23d } : {}),
        }));
    }
  });

  return {
    isReverse,
    mainPoint,
    parallelPoint,
    nextMainPoint,
    nextParallelPoint,
    objects,
    currentWall,
    isInvisible,
  };
};

export const drawPaths = (dxf, points, walls) => {
  const mainPoints = [];
  const parallelPoints = [];
  const curvesGroups = [];
  const currentWalls = [];

  for (let index = 0; index < points.length; index++) {
    const currentPoint = points[index];
    const nextIndex = getNextIndex(points, index);
    const nextPoint = points[nextIndex];

    if (currentPoint.x === nextPoint.x && currentPoint.y === nextPoint.y)
      continue;

    const {
      currentWall,
      mainPoint,
      parallelPoint,
      nextMainPoint,
      nextParallelPoint,
      objects,
      isReverse,
      isInvisible,
    } = getWallParams(walls, currentPoint, nextPoint);

    if (currentWall === null) {
      mainPoints.push(currentPoint);
      parallelPoints.push(parallelPoints[parallelPoints.length - 1]);
    } else {
      mainPoints.push(mainPoint);
      parallelPoints.push(parallelPoint);

      if (isInvisible) {
        mainPoints.push("gap");
        parallelPoints.push("gap");
      }

      if (points.length === 2 && index === 1) {
        mainPoints.push("connect");
        parallelPoints.push("connect");
        mainPoints.unshift("connect");
        parallelPoints.unshift("connect");
      }

      if ((index === 0 || points.length > 2) && !isInvisible) {
        currentWalls.push({ ...currentWall });

        const holeObjects = objects.filter(
          (object) => object?.isWindow || object?.isHole || object?.isDoor
        );
        const wallObjects = objects.filter(
          (object) =>
            object?.isElectricSocket ||
            object?.isSwitch ||
            object?.isHeatingBattery ||
            object?.isElectricPanel ||
            object?.isRedCube ||
            object?.isCylinder ||
            object?.outletElectricalWire
        );

        wallObjects.forEach((object) => {
          if (settingsDxf.showElectricity) {
            drawObject(dxf, object, currentWall, isReverse);
          } else if (
            !object?.isElectricSocket &&
            !object?.isSwitch &&
            !object?.outletElectricalWire &&
            !object?.isElectricPanel
          ) {
            drawObject(dxf, object, currentWall, isReverse);
          }
        });

        holeObjects.forEach((object) => {
          drawObject(dxf, object, currentWall, isReverse);
        });

        if (currentWall?.isBezier) {
          const curveGroup = [];
          curveGroup.push(new Bezier(currentWall.bezier.points));
          const reverseOutlineBezier = [...currentWall.outlineBezier].reverse();
          reverseOutlineBezier.forEach((bezier) => {
            curveGroup.push(new Bezier(bezier.points));
          });

          curvesGroups.push(curveGroup);
          mainPoints.push("gap");
          parallelPoints.push("gap");
        }

        if (!currentWall?.isBezier) {
          holeObjects.forEach((object, objectIndex) => {
            const previousObjects = holeObjects.slice(0, objectIndex);
            const findLastObjectIndex = previousObjects
              .reverse()
              .findIndex(
                (prevObject) =>
                  prevObject?.isWindow ||
                  prevObject?.isHole ||
                  prevObject?.isDoor
              );

            mainPoints.push(object.rA13d);
            parallelPoints.push(object.rA23d);

            mainPoints.push("connect");
            parallelPoints.push("connect");

            mainPoints.push(object.rB13d);
            parallelPoints.push(object.rB23d);
          });
        }

        mainPoints.push(nextMainPoint);
        parallelPoints.push(nextParallelPoint);
      }
    }
  }
  const wallColor = getWallColor(currentWalls[0]);
  if (mainPoints.length && parallelPoints.length) {
    const polylines = [];
    collectPaths(mainPoints, parallelPoints).forEach((part) =>
      polylines.push(part)
    );

    if (settingsDxf.algorithm === "walls") {
      polylines.forEach((polyline) => {
        polyline.forEach((point, index) => {
          if (index < polyline.length - 1) {
            const currentPoint = polyline[index];
            const nextPoint = polyline[getNextIndex(polyline, index)];
            drawLine(dxf, { constantWidth: 0.2 }, currentPoint, nextPoint);
          }
        });
      });

      const subdividePath = (points) => {
        return points.reduce((acc, point, index, array) => {
          acc.push(point);
          if ((index + 1) % 2 !== 0) {
            acc.push("connect");
            acc.push(point);
          }
          return acc;
        }, []);
      };

      const hatches = [];
      collectPaths(
        subdividePath(mainPoints.map((p) => (p === "gap" ? "connect" : p))),
        subdividePath(parallelPoints.map((p) => (p === "gap" ? "connect" : p)))
      ).forEach((part) => hatches.push(part));
      if (hatches.length) {
        hatches.forEach((hatch) =>
          drawHatches(
            dxf,
            {
              trueColor: TrueColor.fromRGB(
                wallColor.r,
                wallColor.g,
                wallColor.b
              ),
            },
            hatch
          )
        );
      }
    } else {
      if (polylines.length)
        drawPolylines(dxf, { constantWidth: 0.2 }, ...polylines);

      const hatches = [];
      collectPaths(
        mainPoints.map((p) => (p === "gap" ? "connect" : p)),
        parallelPoints.map((p) => (p === "gap" ? "connect" : p))
      ).forEach((part) => hatches.push(part));
      const isSameColorWall = checkSameColorWall(currentWalls);
      if (hatches.length && isSameColorWall) {
        drawHatches(
          dxf,
          TrueColor.fromRGB(wallColor.r, wallColor.g, wallColor.b),
          ...hatches
        );
      }
    }
  }

  if (curvesGroups.length) {
    const curveHatches = [];
    curvesGroups.forEach((group) => {
      const hatchesGroup = [];
      group.forEach((bezier, index) => {
        const LUTs = bezier.getLUT(
          Math.max(10, Math.ceil(bezier.length() / 100))
        );
        const result = index === 0 ? LUTs : [...LUTs].reverse();
        hatchesGroup.push(...result);
      });
      curveHatches.push(hatchesGroup);
    });
    drawSpline(dxf, {}, ...curvesGroups.flat());
    drawHatches(
      dxf,
      { trueColor: TrueColor.fromRGB(wallColor.r, wallColor.g, wallColor.b) },
      ...curveHatches
    );
  }

  return { currentWalls };
};
