import { TrueColor } from "@tarikjabiri/dxf";

export { drawColumns } from "./drawColumns";
export { drawInfo, info } from "./drawInfo";
export { drawModules } from "./drawModules";
export { drawObject } from "./drawObject";
export { drawPaths } from "./drawPaths";
export { drawPolygonWalls } from "./drawPolygonWalls";
export {
  calculateAngle,
  extrudeLine,
  movePoint,
  rotatePoint,
  segmentLine,
  splitText,
  squaredDistance,
  thirdRectPoint,
} from "./geometry";
export {
  drawArc,
  drawCircle,
  drawHatches,
  drawImage,
  drawLine,
  drawPolylines,
  drawSizes,
  drawText,
} from "./primitives";

export { fixDXF } from "./fixDXF";
export { getImageInfo, saveFile } from "./saveFile";

export const DIM_SIZE = 100;
export const SMALL_DIM_SIZE = 32;
export const BIG_DIM_SIZE = 128;
export const HUGE_DIM_SIZE = 256;
export const DIM_DEC = 0;
export const COLOR = {
  ORANGE: TrueColor.fromRGB(255, 130, 0),
  RED: TrueColor.fromRGB(255, 0, 0),
  GREEN: TrueColor.fromRGB(0, 255, 0),
  BLUE: TrueColor.fromRGB(0, 0, 255),
  GRAY: TrueColor.fromRGB(129, 129, 129),
};

export const getNextIndex = (array, index) =>
  index === array.length - 1 ? 0 : index + 1;

export const algorithmOptions = [
  { label: "Стандартный", value: "standard" },
  { label: "Отдельными стенами", value: "walls" },
];
export const settingsDxf = {
  showElectricity: true,
  showModules: true,
  showAreaFigures: false,
  algorithm: algorithmOptions[1].value,
};
export const X_GAP = 800;
export const SIZES_OFFSET = 100; // отступ для SIZES (размер стены)
export const DIM_SIZE_80 = 80; // размер шрифта для SIZES (размер стены)
export const DIM_SIZE_50 = 50; // размер шрифта для SIZES (размер стены)
export const DIM_SIZE_30 = 30; // размер шрифта для SIZES (размер стены)
export const OFFSET_LINK = 96; // межстрочное расстояние для ссылки LINK
