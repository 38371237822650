import {Vector2} from "three";
class Node extends Vector2{
    constructor(x,y,type = ''){
        super(x,y);
        this.isLocked = false;
        this.isControl = type==='control';
        this.isRuler = type==='ruler';
        this.isWall = type==='wall';
        this.isPolygonWall = type==='polygonWall';
        this.isFigure = type==='figure';
        this.isLeader = type==='leader';
    }
    set(x,y){
        super.set(x,y);
    }
    distanceToLink(l, unlimited = false) {
        const v = [l.b.x - l.a.x, l.b.y - l.a.y];
        const w = [this.x - l.a.x, this.y - l.a.y];

        if (!unlimited) {
            const c1 = v[0] * w[0] + v[1] * w[1];
            if (c1 <= 0) {
                return { distance: this.distanceToNode(l.a), projection: l.a };
            }

            const c2 = v[0] * v[0] + v[1] * v[1];
            if (c2 <= c1) {
                return { distance: this.distanceToNode(l.b), projection: l.b };
            }

            const t = c1 / c2;
            const projection = {
                x: l.a.x + t * v[0],
                y: l.a.y + t * v[1]
            };

            const distance = Math.abs(((l.a.y - l.b.y) * this.x + (l.b.x - l.a.x) * this.y + (l.a.x * l.b.y - l.b.x * l.a.y)) /
                Math.sqrt((l.b.x - l.a.x) * (l.b.x - l.a.x) + (l.b.y - l.a.y) * (l.b.y - l.a.y)));

            return { distance, projection };
        }

        const A = l.a.y - l.b.y;
        const B = l.b.x - l.a.x;
        const C = l.a.x * l.b.y - l.b.x * l.a.y;

        const projection = {
            x: (B * (B * this.x - A * this.y) - A * C) / (A * A + B * B),
            y: (A * (-B * this.x + A * this.y) - B * C) / (A * A + B * B)
        };

        const distance = Math.abs(A * this.x + B * this.y + C) /
            Math.sqrt(A * A + B * B);

        return { distance, projection };
    }
    distanceToNode(n){
        return Math.sqrt((this.x-n.x)*(this.x-n.x)+(this.y-n.y)*(this.y-n.y))
    }
    isExist() {
        return this.isControl || this.isRuler || this.isWall || this.isPolygonWall || this.isFigure || this.isLeader
    }
}

export default Node;
