export const getWallPlanMaterial = (version, value) => {
  if (!version || version < 250109) {
    switch (value) {
      case "blackFill":
        return "concrete";
      case "hatchDraw":
        return "brick";
      case "beigeFill":
        return "drywall";
      case "greyFill":
        return "block";
      case "pointsDraw":
        return "block";
      default:
        return "concrete";
    }
  }
  return value;
};
