import Plan from "../Classes/Plan/Plan";

export const SHOW_PLANEDIT = "PROJECT/SHOW_PLANE_EDIT";
export const SET_PLAN = "PROJECT/SET_PLAN";
export const SET_VERSIONS = "PROJECT/SET_VERSIONS";
export const SET_VARIANTS = "PROJECT/SET_VARIANTS";
export const SET_MODAL = "PROJECT/SET_MODAL";
export const SET_MODE = "PROJECT/SET_MODE";
export const SET_SECTION = "PROJECT/SET_SECTION";
export const SET_GET_FILE_TYPE = "PROJECT/SET_GET_FILE_TYPE";
export const SET_HUGE_IMAGE = "PROJECT/SET_HUGE_IMAGE";
export const SET_MENU_OPENED = "PROJECT/SET_MENU_OPENED";
export const SET_SIDE_MENU_OPENED = "PROJECT/SET_SIDE_MENU_OPENED";
export const SET_FILTERS = "PROJECT/SET_FILTERS";
export const SET_CAMERA_MODE = "PROJECT/SET_CAMERA_MODE";
export const SET_FORMAT_MODE = "PROJECT/SET_FORMAT_MODE";
export const SET_FORMAT_ESTIMATE = "PROJECT/SET_FORMAT_ESTIMATE";
export const SET_TOOL = "PROJECT/SET_TOOL";
export const SET_SNAP = "PROJECT/SET_SNAP";
export const SET_IMAGE_BG = "PROJECT/SET_IMAGE_BG";
export const ADD_PRELOADER = "PROJECT/ADD_PRELOADER";
export const DEC_PRELOADER = "PROJECT/DEC_PRELOADER";
export const SET_EDIT_MODE = "PROJECT/SET_EDIT_MODE";
export const SET_SAVE_RESULT = "PROJECT/SET_SAVE_RESULT";
export const LOAD_COMPLETED = "PROJECT/LOAD_COMPLETED";
export const SET_ERROR_COUNT = "PROJECT/SET_ERROR_COUNT";
export const SET_STEPS = "PROJECT/SET_STEPS";
export const SET_LIR = "PROJECT/SET_LIR"; //set locationInRooms
export const SET_APPLICATION_CRASH_ERROR =
  "PROJECT/SET_APPLICATION_CRASH_ERROR";
export const SET_CANCEL_TOUCH = "PROJECT/SET_CANCEL_TOUCH";
export const SET_USER_SETUP = "PROJECT/SET_USER_SETUP";
export const SET_LEVEL = "PROJECT/SET_LEVEL";
export const SET_BLUETOOTH_DEVICES = "PROJECT/SET_BLUETOOTH_DEVICES";

export const SHOW_ESTIMATED = "PROJECT/SHOW_ESTIMATED";
export const SET_ALERT = "PROJECT/SET_ALERT";

const InitialState = {
  plan: new Plan(),
  versions: [],
  variants: [],
  mode: "default",
  planeEdit: true,
  showEstimated: false,
  preloaderCount: 1,
  modal: "",
  section: {},
  getFileType: "",
  level: "floor",
  hugeImage: {
    dataUrl: "",
    width: 4000,
    height: 3000,
  },
  menuOpened: 0,
  sideMenuOpened: false,
  editMode: false,
  formatMode: false,
  formatEstimate: null,
  // Оставить отмену выделения кликом снаружи объекта (пол, стена)
  cancelTouch: true,
  userSetup: {
    // Дефолтный вид (3Д или 2Д план)
    defaultViewMode: "3D",
    // Вкл смену вида
    enableChangeViewMode: true,
    // Вкл создание стен
    enableCreateWalls: true,
    // Вкл добавление модулей (объектов)
    enableAddModules: true,
    // Вкл сохранение и автосохранение проекта
    enableSaveProject: true,
    // Вкл создание варианта проекта
    enableCreateVariant: true,
    // Вкл возможность публикации проекта
    enablePublishing: true,
    // Вкл возможность просмотра предыдущих сохранений
    enableViewSaves: true,
    // Вкл возможность просмотра созданных вариантов
    enableViewVariants: true,
    // Вкл функционал сметчика (пока непонятно, что там будет), может можно будет разбить
    enableCostEstimateUI: false,
    // Вкл меню и кнопку-меню сохранения
    enableViewMenu: true,
  },
  saveResult: {
    status: "",
    hash: "",
    href: "",
    token: false,
    tokenAnswer: false,
    tokenRID: false,
    tokenUID: false,
    tokenROLE: false,
    tokenEID: false,
    message: "",
  },
  filters: {
    floors: true,
    walls: true,
    roof: false,
    furniture: true,
    ruler: true,
    wallObjects: false,
    transparent: true,
    angle: true,
    materials: false,
    imageBG: true,
  },
  cameraMode: 0,
  steps: 1,
  alert: null,
  tool: "move", // move|floors|walls|roof|ruler
  snap: 1, // 0, 1, возможны другие варианты в дальнейшем
  ImageBG: {
    // изображение подложка для плана
    dataUrl: "",
    scale: 1000,
    opacity: 50,
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    id: undefined,
    isEditing: false,
  },
  loadCompleted: {
    isCompleted: false,
    startTime: null,
  },
  errorCount: 0,
  locationInRooms: {
    up: undefined,
    right: undefined,
    bottom: undefined,
    left: undefined,
  },
  applicationCrashError: null,
  devices: {
    bluetooth: {
      status: "disable",
    },
  },
};

export const actionsState = {
  showPlaneEdit: (value) => {
    return { type: SHOW_PLANEDIT, value };
  },
  setPlan: (value) => {
    return { type: SET_PLAN, value };
  },
  setVersions: (value) => {
    return { type: SET_VERSIONS, value };
  },
  setVariants: (value) => {
    return { type: SET_VARIANTS, value };
  },
  setModal: (value) => {
    return { type: SET_MODAL, value };
  },
  setMode: (value) => {
    return { type: SET_MODE, value };
  },
  setSection: (value) => {
    return { type: SET_SECTION, value };
  },
  setGetFileType: (value) => {
    return { type: SET_GET_FILE_TYPE, value };
  },
  setHugeImage: (value) => {
    return { type: SET_HUGE_IMAGE, value };
  },
  setMenuOpened: (value) => {
    return { type: SET_MENU_OPENED, value };
  },
  setFilters: (value) => {
    return { type: SET_FILTERS, value };
  },
  setCameraMode: (value) => {
    return { type: SET_CAMERA_MODE, value };
  },
  setFormatMode: (value) => {
    return { type: SET_FORMAT_MODE, value };
  },
  setFormatEstimate: (value) => {
    return { type: SET_FORMAT_ESTIMATE, value };
  },
  setTool: (value) => {
    return { type: SET_TOOL, value };
  },
  setSnap: (value) => {
    return { type: SET_SNAP, value };
  },
  setAlert: (value) => {
    return { type: SET_ALERT, value };
  },
  setImageBG: (value) => {
    return { type: SET_IMAGE_BG, value };
  },
  decPreloader: () => {
    return { type: DEC_PRELOADER };
  },
  addPreloader: () => {
    return { type: ADD_PRELOADER };
  },
  setEditMode: (value) => {
    return { type: SET_EDIT_MODE, value };
  },
  setSaveResult: (value) => {
    return { type: SET_SAVE_RESULT, value };
  },
  loadCompleted: (value) => {
    return { type: LOAD_COMPLETED, value };
  },
  setLocationInRooms: (value) => {
    return { type: SET_LIR, value };
  },
  setErrorCount: (value) => {
    return { type: SET_ERROR_COUNT, value };
  },
  setSteps: (value) => {
    return { type: SET_STEPS, value };
  },
  setSideMenuOpened: (value) => {
    return { type: SET_SIDE_MENU_OPENED, value };
  },
  setApplicationCrashError: (value) => {
    return { type: SET_APPLICATION_CRASH_ERROR, value };
  },
  setCancelTouch: (value) => {
    return { type: SET_CANCEL_TOUCH, value };
  },
  setUserSetup: (value) => {
    return { type: SET_USER_SETUP, value };
  },
  setLevel: (value) => {
    return { type: SET_LEVEL, value };
  },
  setShowEstimated: (value) => {
    return { type: SHOW_ESTIMATED, value };
  },
  setBluetoothDevices: (value) => {
    return { type: SET_BLUETOOTH_DEVICES, value };
  },
};

const projectReducer = (state = InitialState, action) => {
  switch (action.type) {
    case SHOW_PLANEDIT: {
      return {
        ...state,
        planeEdit: action.value,
      };
    }
    case SHOW_ESTIMATED: {
      return {
        ...state,
        showEstimated: action.value,
      };
    }
    case SET_PLAN: {
      return {
        ...state,
        plan: action.value,
      };
    }
    case SET_VERSIONS: {
      return {
        ...state,
        versions: action.value,
      };
    }
    case SET_VARIANTS: {
      return {
        ...state,
        variants: action.value,
      };
    }
    case SET_MODAL: {
      return {
        ...state,
        modal: action.value,
      };
    }
    case SET_MODE: {
      return {
        ...state,
        mode: action.value,
      };
    }
    case SET_SECTION: {
      return {
        ...state,
        section: action.value,
      };
    }
    case SET_GET_FILE_TYPE: {
      return {
        ...state,
        getFileType: action.value,
      };
    }
    case SET_HUGE_IMAGE: {
      return {
        ...state,
        hugeImage: action.value,
      };
    }
    case SET_MENU_OPENED: {
      return {
        ...state,
        menuOpened: action.value,
      };
    }
    case SET_SIDE_MENU_OPENED: {
      return {
        ...state,
        sideMenuOpened: action.value,
      };
    }
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.value,
      };
    }
    case SET_CAMERA_MODE: {
      return {
        ...state,
        cameraMode: action.value,
      };
    }
    case SET_TOOL: {
      return {
        ...state,
        tool: action.value,
      };
    }
    case SET_SNAP: {
      return {
        ...state,
        snap: action.value,
      };
    }
    case SET_ALERT: {
      return {
        ...state,
        alert: action.value,
      };
    }
    case SET_IMAGE_BG: {
      return {
        ...state,
        ImageBG: action.value,
      };
    }
    case ADD_PRELOADER: {
      return {
        ...state,
        preloaderCount: state.preloaderCount + 1,
      };
    }
    case DEC_PRELOADER: {
      return {
        ...state,
        preloaderCount: state.preloaderCount - 1,
      };
    }
    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.value,
      };
    }
    case SET_FORMAT_MODE: {
      return {
        ...state,
        formatMode: action.value,
        showEstimated: action.value,
        ...(!action.value ? { formatEstimate: null } : {}),
      };
    }
    case SET_FORMAT_ESTIMATE: {
      return {
        ...state,
        formatEstimate: action.value,
      };
    }
    case SET_SAVE_RESULT: {
      return {
        ...state,
        saveResult: action.value,
      };
    }
    case LOAD_COMPLETED: {
      return {
        ...state,
        loadCompleted: action.value,
      };
    }
    case SET_LIR: {
      return {
        ...state,
        locationInRooms: action.value,
      };
    }
    case SET_STEPS: {
      return {
        ...state,
        steps: action.value,
      };
    }
    case SET_ERROR_COUNT: {
      return {
        ...state,
        errorCount: action.value > 0 ? state.errorCount + action.value : 0,
      };
    }
    case SET_APPLICATION_CRASH_ERROR: {
      return {
        ...state,
        applicationCrashError: action.value,
      };
    }
    case SET_CANCEL_TOUCH: {
      return {
        ...state,
        cancelTouch: action.value,
      };
    }
    case SET_USER_SETUP: {
      return {
        ...state,
        userSetup: action.value,
      };
    }
    case SET_LEVEL: {
      return {
        ...state,
        level: action.value,
      };
    }
    case SET_BLUETOOTH_DEVICES: {
      return {
        ...state,
        devices: { ...state.devices, bluetooth: action.value },
      };
    }
    default: {
      return state;
    }
  }
};

export default projectReducer;
