import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Line3, Vector2, Vector3 } from "three";
import Column from "../Classes/Column";
import Link from "../Classes/Link";
import Node from "../Classes/Node";
import BWallInfo from "./BWallInfo/BWallInfo";
import { ColumnInfo } from "./ColumnInfo";
import ContextMenu from "./ContextMenu";
import FloorInfo from "./FloorInfo";
import ImageBG from "./ImageBG";
import LinkInfo from "./LinkInfo";
import ModuleInfo from "./ModuleInfo";
import ModuleMenu from "./ModuleMenu";
import PointInfo from "./PointInfo";
import PolygonWallInfo from "./PolygonWallInfo";
import Projects from "./Projects";
import WallObjectInfo from "./WallObjectInfo";

import { wallConstants } from "../Classes/Wall/wallConstants";
import {
  sendRedrawEvent,
  sendRedrawSimpleEvent,
  sendUnselectEvent,
} from "../Helpers/Events";
import {
  getBGIntersect,
  getBGProps,
  getIntersection,
  is_touch_device,
  polyPoint,
} from "../Helpers/functions";
import {
  actionsState as projectState,
  actionsState as ProjectState,
} from "../Redux/project";
import { EditField, usePlanField } from "./Features/Plan";
import { EstimateEdit, EstimateObject, ObjectInfo } from "./Features/SideMenu";
import { EstimateFormatterMode } from "./Features/SideMenu/Estimate";
import { checkRoom } from "./Features/SideMenu/Estimate/utils";
import { checkColumn } from "./Features/SideMenu/Estimate/utils/checkRoom";
import {
  drawColumn,
  drawGrid,
  drawLock,
  drawSize,
  drawWallObjects,
} from "./PlanEditor/draw/";
import { autoCanvasMoveEvent } from "./PlanEditor/events";
import {
  calcAngleDeg,
  checkMatchNode,
  checkPointLiesOnSegment,
  Convert,
  getCommonPoint,
} from "./PlanEditor/helpers/";
import {
  colors,
  columnParams,
  getCycleByPoint,
  getLinkByPoint,
  getWallByPoint,
  isPointInsidePolygon,
  rgbToHex,
} from "./Utils";
import {
  checkHoverColumn,
  getParallelEdgeLine3,
  initColumnPoints,
  stickColumnToWall,
} from "./Utils/columnsCalculation";
import { stickPointToWall } from "./Utils/pointUtils";

// todo: Вынести
let index = null;

const isInside = (point, points) => {
  const x = point.x;
  const y = point.y;

  let inside = false;

  for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
    let xi = points[i][0],
      yi = points[i][1];
    let xj = points[j][0],
      yj = points[j][1];

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

const isInsideBoundingBox = (point, boundingBox) => {
  return (
    point?.x >= boundingBox?.minX &&
    point?.x <= boundingBox?.maxX &&
    point?.y >= boundingBox?.minY &&
    point?.y <= boundingBox?.maxY
  );
};

const distNodeToNode = (n1, n2) => {
  return Math.sqrt((n1.x - n2.x) ** 2 + (n1.y - n2.y) ** 2);
};

const projectPointOntoLine = (line, point) => {
  const { a, b } = line;
  const ap = { x: point.x - a.x, y: point.y - a.y };
  const ab = { x: b.x - a.x, y: b.y - a.y };

  const ab2 = ab.x * ab.x + ab.y * ab.y;
  const ap_ab = ap.x * ab.x + ap.y * ab.y;
  const t = Math.max(0, Math.min(1, ap_ab / ab2));

  return {
    x: a.x + ab.x * t,
    y: a.y + ab.y * t,
  };
};

const snapPointToLines = (lines, point, threshold) => {
  let nearestPoint = null;
  let minDistance = Infinity;

  for (const line of lines) {
    const { a, b } = line;

    const distanceToA = distNodeToNode(point, a);
    const distanceToB = distNodeToNode(point, b);

    if (distanceToA < threshold && distanceToA < minDistance) {
      minDistance = distanceToA;
      nearestPoint = a;
      break;
    }

    if (distanceToB < threshold && distanceToB < minDistance) {
      minDistance = distanceToB;
      nearestPoint = b;
      break;
    }

    const projectedPoint = projectPointOntoLine(line, point);
    const distanceToProjected = distNodeToNode(point, projectedPoint);

    if (distanceToProjected < threshold && distanceToProjected < minDistance) {
      minDistance = distanceToProjected;
      nearestPoint = projectedPoint;
    }
  }

  return nearestPoint || point;
};

const distanceRayToModule = (origin, direction, m) => {
  const p1 = m.position
    .clone()
    .add(new Vector2(-m.size.pivot.x, -m.size.pivot.y))
    .rotateAround(m.position, -m.angle);
  const p2 = m.position
    .clone()
    .add(new Vector2(m.size.width - m.size.pivot.x, -m.size.pivot.y))
    .rotateAround(m.position, -m.angle);
  const p3 = m.position
    .clone()
    .add(
      new Vector2(m.size.width - m.size.pivot.x, m.size.height - m.size.pivot.y)
    )
    .rotateAround(m.position, -m.angle);
  const p4 = m.position
    .clone()
    .add(new Vector2(-m.size.pivot.x, m.size.height - m.size.pivot.y))
    .rotateAround(m.position, -m.angle);
  let d = Infinity;

  const dist = (origin, direction, a, b) => {
    const a1 = -direction.y;
    const b1 = direction.x;
    const c1 =
      origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

    const a2 = a.y - b.y;
    const b2 = b.x - a.x;
    const c2 = a.x * b.y - b.x * a.y;

    const det = (a, b, c, d) => a * d - b * c;

    const zn = det(a1, b1, a2, b2);

    if (Math.abs(zn) < 0.0000001) return Infinity;
    else {
      const v = new Vector2(
        -det(c1, b1, c2, b2) / zn,
        -det(a1, c1, a2, c2) / zn
      );
      //проверяем сторону луча
      if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
      //проверяем что точка на отрезке
      if (a.x < b.x && (v.x < a.x || v.x > b.x)) return Infinity;
      if (a.x > b.x && (v.x > a.x || v.x < b.x)) return Infinity;
      if (a.x === b.x && a.y < b.y && (v.y < a.y || v.y > b.y)) return Infinity;
      if (a.x === b.x && a.y > b.y && (v.y > a.y || v.y < b.y)) return Infinity;
      return v.sub(origin).length();
    }
  };
  let _d = dist(origin, direction, p1, p2);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p2, p3);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p3, p4);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p4, p1);
  if (_d < d) d = _d;

  return d;
};

const distanceRayToWall = (origin, direction, wall) => {
  const p1 = new Vector2(wall.innerLink.a.x, wall.innerLink.a.y);
  const p2 = new Vector2(wall.innerLink.b.x, wall.innerLink.b.y);
  const p3 = new Vector2(wall.parallelLink.a.x, wall.parallelLink.a.y);
  const p4 = new Vector2(wall.parallelLink.b.x, wall.parallelLink.b.y);
  let d = Infinity;

  const dist = (origin, direction, a, b) => {
    const a1 = -direction.y;
    const b1 = direction.x;
    const c1 =
      origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

    const a2 = a.y - b.y;
    const b2 = b.x - a.x;
    const c2 = a.x * b.y - b.x * a.y;

    const det = (a, b, c, d) => a * d - b * c;

    const zn = det(a1, b1, a2, b2);

    if (Math.abs(zn) < 0.0000001) return Infinity;
    else {
      const v = new Vector2(
        -det(c1, b1, c2, b2) / zn,
        -det(a1, c1, a2, c2) / zn
      );
      if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
      if (!checkPointLiesOnSegment(a, b, v)) return Infinity;
      return v.sub(origin).length();
    }
  };
  let _d = dist(origin, direction, p1, p2);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p2, p4);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p3, p4);
  if (_d < d) d = _d;
  _d = dist(origin, direction, p1, p3);
  if (_d < d) d = _d;

  return d;
};

const selectedText = {
  diagonalCenter: null,
  boundingBox: null,
  readyToMove: false,
  height: 0,
  width: 0,
  getPosition: (cycle, diagonalCenter) => {
    return {
      x: diagonalCenter.x - selectedText.width / 2,
      y:
        diagonalCenter.y -
        selectedText.height / 2 -
        (cycle?.objTitle?.length ? selectedText.height : 0),
    };
  },
  calculateBoundingBox: (cycle, diagonalCenter, point) => {
    const textHeight = 25;
    const textWidth =
      Math.max(cycle?.objTitle?.length || 0, cycle?.squareText?.length || 0) *
      8;
    const rect = {
      minX: diagonalCenter?.x - textWidth / 2,
      maxX: diagonalCenter?.x + textWidth / 2,
      minY:
        diagonalCenter?.y -
        textHeight / 2 -
        (cycle?.objTitle?.length ? selectedText.height : 0),
      maxY: diagonalCenter?.y + textHeight / 2,
    };

    if (isInsideBoundingBox(point, rect)) {
      selectedText.diagonalCenter = cycle.diagonalCenter;
      selectedText.width = textWidth;
      selectedText.height = textHeight;
      selectedText.boundingBox = rect;
    } else {
      selectedText.diagonalCenter = null;
      selectedText.boundingBox = null;
    }

    return rect;
  },
  checkIsReadyToMove: (point) => {
    if (
      selectedText.boundingBox &&
      isInsideBoundingBox(point, selectedText.boundingBox)
    ) {
      selectedText.readyToMove = true;
      document.body.style.cursor = "move";
    } else {
      selectedText.readyToMove = false;
      document.body.style.cursor = "default";
    }
  },
  move: (moveX, moveY) => {
    const deltaPoint = { x: moveX, y: moveY };
    selectedText.diagonalCenter.x += deltaPoint.x;
    selectedText.diagonalCenter.y += deltaPoint.y;
    selectedText.diagonalCenter.isMoved = true;
  },
  cancelMove: () => {
    selectedText.diagonalCenter = null;
    selectedText.boundingBox = null;
    selectedText.readyToMove = false;
    document.body.style.cursor = "default";
  },
};

export const selectedRoom = {
  points: null, // точки выделенной комнаты
  cycle: null,
  parts: [],
  ready2Move: false, // флаг - готова к движению
  // showMenu: false, // флаг - надо ли показывать меню

  getPoints: (cycle) => {
    //берем точку и выделяем комнату в которую она входит
    if (selectedRoom.points) {
      selectedRoom.points.length = 0;
      selectedRoom.cycle = null;
      selectedRoom.points.length = 0;
    }
    const points = cycle._points;

    const tempPoints = points.map((point) => {
      return [point.x, point.y];
    });
    const jsonStringifyPoints = tempPoints.map((point) =>
      JSON.stringify(point)
    );
    const setPoints = new Set(jsonStringifyPoints);
    const arrayPoints = Array.from(setPoints);

    selectedRoom.cycle = cycle;
    selectedRoom.points = Array.from(
      arrayPoints.map((point) => JSON.parse(point))
    );
  },

  check: (point) => {
    //проверяем что точка входи в выбранную комнату
    if (selectedRoom.points) {
      return isInside(point, selectedRoom.points);
    }
    return false;
  },

  ready2MoveFN: (point, plan, modules) => {
    //проверяем готовы ли перемещать комнату
    if (selectedRoom.check(point)) {
      selectedRoom.ready2Move = true;
      document.body.style.cursor = "move";
      selectedRoom.parts = [];

      const activeObject = selectedRoom.cycle;

      plan.cycles.forEach((cycle) => {
        if (
          cycle.isFigure &&
          cycle.points.every((point) =>
            isPointInsidePolygon(point, activeObject.points)
          )
        ) {
          selectedRoom.parts.push(cycle);
        }
      });

      modules.forEach((module) => {
        if (checkRoom(module, activeObject)) {
          selectedRoom.parts.push(module);
        }
      });

      activeObject.links.forEach((link) => {
        plan.bWalls.forEach((wall) => {
          if (wall.mainLink === link) {
            selectedRoom.parts.push(wall);

            if (wall.connectedNodes) {
              selectedRoom.parts.push(...wall.connectedNodes);
            }

            if (plan.columns?.length) {
              const addColumn = (columnIndex) => {
                const currentColumn = plan.columns?.[columnIndex];
                if (currentColumn) {
                  selectedRoom.parts.push(currentColumn);
                }
              };
              if (wall.leftCols?.length) wall.leftCols.forEach(addColumn);
              if (wall.rightCols?.length) wall.rightCols.forEach(addColumn);
            }
          }
        });
      });

      plan.columns.forEach((column) => {
        if (
          checkColumn(column, activeObject) &&
          !selectedRoom.parts.includes(column)
        ) {
          selectedRoom.parts.push(column);
        }
      });

      plan.links.forEach((link) => {
        if (
          (link.isFigure || link.isRuler) &&
          isPointInsidePolygon(link.a, activeObject.points) &&
          isPointInsidePolygon(link.b, activeObject.points)
        ) {
          selectedRoom.parts.push(link);
        }
      });
    } else {
      selectedRoom.ready2Move = false;
      document.body.style.cursor = "default";
      selectedRoom.points = null;
      selectedRoom.cycle = null;
      selectedRoom.parts = [];
    }
  },

  move: (plan, modules, activeObject, moveX, moveY) => {
    const deltaPoint = {
      x: moveX,
      y: moveY,
    };

    if (activeObject?.isCycle) {
      [
        ...new Set(selectedRoom.parts.filter((part) => part?.isPolygonWall)),
      ].forEach((node) => {
        node.x += deltaPoint.x;
        node.y += deltaPoint.y;
      });

      selectedRoom.parts.forEach((part) => {
        if (part.isCycle && part.isFigure) {
          part.diagonalCenter.x += deltaPoint.x;
          part.diagonalCenter.y += deltaPoint.y;
          part.texRect.x += deltaPoint.x;
          part.texRect.y += deltaPoint.y;
        }
        if (part.isWall) {
          const wall = part;

          wall.nodes.forEach((node) => {
            node.x += deltaPoint.x;
            node.y += deltaPoint.y;
          });

          if (wall.isBezier) {
            wall.outlineBezier.forEach((bezier) => {
              bezier.points[0].x += deltaPoint.x;
              bezier.points[0].y += deltaPoint.y;
              bezier.points[1].x += deltaPoint.x;
              bezier.points[1].y += deltaPoint.y;
              bezier.points[2].x += deltaPoint.x;
              bezier.points[2].y += deltaPoint.y;
              bezier.points[3].x += deltaPoint.x;
              bezier.points[3].y += deltaPoint.y;
            });
            wall.inlineBezier.forEach((bezier) => {
              bezier.points[0].x += deltaPoint.x;
              bezier.points[0].y += deltaPoint.y;
              bezier.points[1].x += deltaPoint.x;
              bezier.points[1].y += deltaPoint.y;
              bezier.points[2].x += deltaPoint.x;
              bezier.points[2].y += deltaPoint.y;
              bezier.points[3].x += deltaPoint.x;
              bezier.points[3].y += deltaPoint.y;
            });
            wall.bezier.points.forEach((point) => {
              point.x += deltaPoint.x;
              point.y += deltaPoint.y;
            });
            wall.bezierControlPoint_1A.x += deltaPoint.x;
            wall.bezierControlPoint_1A.y += deltaPoint.y;
            wall.bezierControlPoint_1B.x += deltaPoint.x;
            wall.bezierControlPoint_1B.y += deltaPoint.y;

            wall.inlineBezierLUT = wall.inlineBezier.map((bezier) =>
              bezier.getLUT(32).map((lut) => ({
                ...lut,
                x: lut.x + deltaPoint.x,
                y: lut.y + deltaPoint.y,
              }))
            );
          }
        }
        if (part.isColumn) {
          const column = part;
          column.x += deltaPoint.x;
          column.y += deltaPoint.y;
        }
        if (!part.isCycle && (part.isFigure || part.isRuler)) {
          const link = part;
          link.a.x += deltaPoint.x;
          link.b.x += deltaPoint.x;
          link.a.y += deltaPoint.y;
          link.b.y += deltaPoint.y;
        }
        if (part.isModule) {
          const module = part;
          module.position.x += deltaPoint.x;
          module.position.y += deltaPoint.y;
        }
      });

      activeObject.diagonalCenter.x += deltaPoint.x;
      activeObject.diagonalCenter.y += deltaPoint.y;
      activeObject.texRect.x += deltaPoint.x;
      activeObject.texRect.y += deltaPoint.y;
    }
  },

  moveCancel: () => {
    selectedRoom.ready2Move = false;
    selectedRoom.points = null;
    selectedRoom.cycle = null;
    selectedRoom.parts = [];
    document.body.style.cursor = "default";
  },
};

let angles = {};

const PlanEditor = (props) => {
  const ref = useRef(null);
  const refFake = useRef(null);
  const refCanvasImageBG = useRef(null);
  const refCanvasMoveImageBGOverlay = useRef(null);
  const dispatch = useDispatch();
  const updateFlag = useSelector((store) => store.modules.updateModuleFlag);
  const filters = useSelector((state) => state.project.filters);
  const tool = useSelector((state) => state.project.tool);
  const snap = useSelector((state) => state.project.snap);
  const enableCreateWalls = useSelector(
    (store) => store.project.userSetup.enableCreateWalls
  );
  const ImageBGData = useSelector((state) => state.project.ImageBG);
  const steps = useSelector((state) => state.project.steps);
  const showEstimated = useSelector((state) => state.project.showEstimated);
  const modal = useSelector((store) => store.project.modal);
  const mode = useSelector((store) => store.project.mode);
  const level = useSelector((state) => state.project.level);
  const cancelTouch = useSelector((state) => state.project.cancelTouch);

  const { value, position, object, onChange, clearField } = usePlanField();

  const [_activeObject, setActiveObject] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const timerRef = useRef(0);
  const contextMenu = useRef(null);

  let activeObject = null;
  let prevTouch = null;

  const setMoveTool = () => {
    dispatch(projectState.setTool("move"));
    dispatch(projectState.setModal("move"));
  };

  const setUndo = (obj) => {
    dispatch(projectState.addPreloader());
    props.plan.setActionUndo(obj);
    dispatch(projectState.decPreloader());
  };

  useEffect(() => {
    const plan = props.plan;

    //стартует при монтаже компонента
    const canvas = ref.current;
    const canvasFake = refFake.current;
    const ctx = canvas.getContext("2d");
    const ctxFake = canvasFake.getContext("2d");

    const width = window.innerWidth;
    const height = window.innerHeight;

    let { devicePixelRatio: ratio = 1 } = window;
    ratio = ratio < 1 ? 2 - ratio : ratio;

    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = ctx.width = width * ratio;
      canvas.height = ctx.height = height * ratio;
      ctx.scale(ratio, ratio);

      canvasFake.width = ctxFake.width = width * ratio;
      canvasFake.height = ctxFake.height = height * ratio;
      ctxFake.scale(ratio, ratio);
    }

    //стэйт для зума,таскания и состояния клика
    let offsetX = plan.offsetX;
    let offsetY = plan.offsetY;

    if (!plan.offsetX || !plan.offsetY) {
      offsetX = canvas.width / 2 - canvas.width / 2 / ratio;
      offsetY = canvas.height / 2 - canvas.height / 2 / ratio;
    }
    if (plan.ratio !== ratio) {
      plan.offsetX = offsetX;
      plan.offsetY = offsetY;
      plan.ratio = ratio;
    }

    let zoom = 0.1; // 0.02-4.0 в roomle
    let drag = false; //флаг таскания плана
    let dragDistance = false;

    let rotate = false;
    let hoverObject = null;
    let clickObject = null;

    let clickMousePoint = null;
    let startAngle = null;
    let startPosition = null;

    let columnSizing = false;
    let columnSizingDirection = false;
    let columnSizingPrev = 0;
    let isHoverColumn = false;
    let isContextMenu = false;

    let isWheelEvent = false;
    let touchPrevEvent = null;
    let scaling = false;
    let touchesZoom;
    let distance = 0;
    let lastDistance = 0;
    let moving = 0;
    let clickCanvas = false;
    let currentWall = false;

    let undoModulePrev = null;

    let infos = [];

    if (plan.zoom !== zoom) {
      zoom = plan.zoom;
    } else {
      plan.zoom = zoom;
    }

    const getCanvasParams = () => ({
      plan,
      filters,
      canvas,
      canvasFake,
      ctx,
      ctxFake,
      offsetX,
      offsetY,
      ratio,
      zoom,
      activeObject,
      hoverObject,
      level,
      infos,
      mode,
    });

    const distForMerge = () => Math.min(Math.max(3, 13 / zoom), 100);

    const _setActiveObject = (obj) => {
      if (obj) activeObject = obj.object;
      if (!obj || !obj?.object?.isFloor) plan.setCycleActive(-1);

      plan.setActiveObject(obj);
      setActiveObject(obj);
    };

    const draw = (ctx, plan) => {
      infos = [];
      ctx.fillStyle = "#EEEEEE";
      ctx.strokeStyle = "#000000";
      ctx.font = "12px Arial";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.clearRect(0, 0, ctx.width, ctx.height);

      drawGrid(
        getCanvasParams(),
        ImageBGData,
        refCanvasImageBG,
        refCanvasMoveImageBGOverlay
      );

      if (filters.floors) {
        const sortFloors = [];
        plan.cycles.forEach((cycle) => {
          if (cycle.isFigure) {
            sortFloors.push(cycle);
          } else {
            sortFloors.unshift(cycle);
          }
        });
        sortFloors.forEach((cycle) => {
          drawFloor(cycle);
        });
        plan.links.forEach((link) => {
          if (link?.isFigure) {
            drawFigure(link);
          }
        });
        if (plan.virtualLink && plan.virtualLink.isFigure) {
          drawFigure(plan.virtualLink);
        }
        plan.nodes.map((n) => {
          if (n.isFigure) {
            drawNode(n);
          }
        });
        if (plan.virtualNode && plan.virtualNode.isFigure) {
          drawNode(plan.virtualNode);
        }
      }

      if (filters.walls) {
        if (plan.virtualColumn && plan.virtualColumn.isColumn) {
          drawColumn(getCanvasParams(), {
            column: plan.virtualColumn,
            walls: plan.bWalls,
          });
        }

        plan.columns.forEach((column) => {
          drawColumn(getCanvasParams(), {
            column,
            walls: plan.bWalls,
          });
          drawWallObjects(getCanvasParams(), column, showEstimated);
        });

        if (
          activeObject !== null &&
          activeObject.isWall &&
          !activeObject.isLink
        ) {
          drawWall(activeObject);
        }

        if (plan.virtualLink && plan.virtualLink.isWall) {
          drawLink(plan.virtualLink);
        }

        if (plan.virtualPolygonWall && plan.virtualPolygonWall.isPolygonWall) {
          drawWall(plan.virtualPolygonWall);
        }

        plan.bWalls.map((wall) => {
          drawWall(wall);
        });

        plan.polygonWalls.forEach((wall) => {
          drawWall(wall);
        });

        plan.bWalls.map((wall) => {
          drawWallObjects(getCanvasParams(), wall, showEstimated);
        });

        plan.nodes.map((n) => {
          if (n.isWall || n.isControl || n.isPolygonWall) {
            drawNode(n);
          }
        });

        if (
          plan.virtualNode &&
          (plan.virtualNode.isWall || plan.virtualNode.isPolygonWall)
        ) {
          drawNode(plan.virtualNode);
        }
      }

      if (filters.furniture) {
        props.modules.map((m) => drawModule(m));
      }

      infos.map((point) => {
        drawInfos(point);
      });

      if (plan.tempNode) {
        // точка для тестов
        ctx.beginPath();
        let r = 30 * zoom;
        ctx.fillStyle = "red";
        ctx.arc(plan.tempNode.x, plan.tempNode.y, r, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.beginPath();
        ctx.fillStyle = "green";
        r = 15 * zoom;
        ctx.arc(plan.tempNode.x, plan.tempNode.y, r, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
      }
      if (plan.magnetization.nodeOnScreen) {
        // точка примагничивания помещения
        drawLock(
          ctx,
          plan.magnetization.nodeOnScreen,
          plan.magnetization.locked
        );
      }

      if (filters.ruler) {
        if (plan.virtualLink && plan.virtualLink.isLeader) {
          drawLeader(plan.virtualLink);
        }

        plan.nodes.map((n) => {
          if (
            n.isLeader &&
            activeObject !== null &&
            activeObject?.isLeader &&
            (activeObject?.a === n || activeObject?.b === n)
          ) {
            drawNode(n);
          }
        });

        if (filters.walls) {
          plan.bWalls.map((w) => {
            drawSize(getCanvasParams(), w.innerLink, w.mainLink.lrBuild);
          });
          if (plan.virtualLink && plan.virtualLink.isWall)
            drawSize(
              getCanvasParams(),
              plan.virtualLink,
              plan.virtualLink.lrBuild
            );

          if (
            activeObject !== null &&
            activeObject.isWall &&
            !activeObject.isLink
          ) {
            drawSize(
              getCanvasParams(),
              activeObject.innerLink,
              activeObject.mainLink.lrBuild
            );
          }
        }
        if (filters.floors) {
          plan.links.map((link) => {
            if (link.isFigure || link.isPolygonWall) {
              drawSize(getCanvasParams(), link);
            }
          });
          if (plan.virtualLink && plan.virtualLink.isFigure) {
            drawSize(getCanvasParams(), plan.virtualLink);
          }
        }

        plan.links.map((link) => {
          if (link.isRuler) {
            drawRuler(link);
          }
        });
        if (plan.virtualLink && plan.virtualLink.isRuler) {
          drawRuler(plan.virtualLink);
        }

        plan.links.forEach((link) => {
          if (link?.isLeader) {
            drawLeader(link);
          }
        });
      }
    };

    const drawFloor = (cycle) => {
      const { text, main, estimated, selected, selectedStroke } = colors;

      const fillColor = cycle?.rgbColor
        ? "#" +
          ("000000" + rgbToHex(cycle.rgb.r, cycle.rgb.g, cycle.rgb.b)).slice(-6)
        : main.floor;

      ctx.font = "12px Arial";
      ctx.fillStyle = fillColor;
      ctx.strokeStyle = main.floorStroke;

      if (showEstimated && cycle.estimate && cycle.estimate.length === 0) {
        ctx.fillStyle = estimated.floor;
        ctx.strokeStyle = estimated.floorStroke;
      }

      const isSelected =
        activeObject !== null && activeObject.isCycle && activeObject === cycle;

      if (isSelected) {
        ctx.fillStyle = selected;
        ctx.strokeStyle = selectedStroke;
      }

      if (
        cycle?.image &&
        cycle?.texRect &&
        !isSelected &&
        !cycle?.rgbColor &&
        filters.materials
      ) {
        const point = Convert.toPixel(
          new Node(cycle.texRect.x, cycle.texRect.y),
          getCanvasParams()
        );
        ctx.drawImage(
          cycle.image,
          point.x,
          point.y,
          cycle.texRect.w * zoom,
          cycle.texRect.h * zoom
        );
      } else {
        cycle.points.forEach((node, index) => {
          const point = Convert.toPixel(
            new Node(node.x, node.y),
            getCanvasParams()
          );
          if (index === 0) {
            ctx.beginPath();
            ctx.moveTo(point.x, point.y);
          }

          let wall = null;
          let isReverse = false;
          if (index < cycle.points.length - 1) {
            const _a = node;
            const _b = cycle.points[index + 1];
            wall = plan.bWalls.find((w) => {
              if (w.innerLink.a === _a && w.innerLink.b === _b) {
                return true;
              } else if (w.innerLink.a === _b && w.innerLink.b === _a) {
                isReverse = true;
                return true;
              }
              return false;
            });
          }

          if (wall && wall.isArc && wall.arcRadius1) {
            const pivotPoint = Convert.toPixel(
              wall.nodes[4],
              getCanvasParams()
            );
            const arcRadius1 = Math.ceil(wall.arcRadius * zoom);
            // const arcRadius2 = Math.ceil((wall.arcRadius+wall.mainLink.depth/2)*zoom);

            // ctx.lineWidth = wall.mainLink.depth*zoom;
            // ctx.beginPath();
            if (wall.mainLink.lrBuild === "left")
              ctx.arc(
                pivotPoint.x,
                pivotPoint.y,
                arcRadius1,
                wall.arcRadius1.angle(),
                wall.arcRadius2.angle()
              );
            else
              ctx.arc(
                pivotPoint.x,
                pivotPoint.y,
                arcRadius1,
                wall.arcRadius2.angle(),
                wall.arcRadius1.angle()
              );
            // ctx.stroke();
          } else if (wall && wall.isBezier) {
            const a = Convert.toPixel(wall.nodes[0], getCanvasParams());
            const b = Convert.toPixel(wall.nodes[1], getCanvasParams());
            const control_1A = Convert.toPixel(
              wall.bezierControlPoint_1A,
              getCanvasParams()
            );
            const control_1B = Convert.toPixel(
              wall.bezierControlPoint_1B,
              getCanvasParams()
            );

            if (isReverse) {
              ctx.lineTo(b.x, b.y);
              ctx.bezierCurveTo(
                control_1B.x,
                control_1B.y,
                control_1A.x,
                control_1A.y,
                a.x,
                a.y
              );
            } else {
              ctx.lineTo(a.x, a.y);
              ctx.bezierCurveTo(
                control_1A.x,
                control_1A.y,
                control_1B.x,
                control_1B.y,
                b.x,
                b.y
              );
            }
          } else {
            ctx.lineTo(point.x, point.y);
            ctx.stroke();
          }
        });

        ctx.closePath();
        ctx.stroke();
        ctx.fill();
      }

      if (cycle?.diagonalCenter) {
        ctx.fillStyle = text;
        const diagonalCenterPixel = Convert.toPixel(
          cycle.diagonalCenter,
          getCanvasParams()
        );
        ctx.fillText(
          cycle.squareText,
          diagonalCenterPixel.x,
          diagonalCenterPixel.y
        );

        ctx.font = "14px Arial";
        ctx.fillText(
          cycle.objTitle,
          diagonalCenterPixel.x,
          diagonalCenterPixel.y - 20
        );

        if (cycle.objComment !== "" || cycle.objImages.length > 0) {
          infos.push({
            type: "ruler",
            x: diagonalCenterPixel.x,
            y: diagonalCenterPixel.y - 400 * zoom,
          });
        }
      }

      if (
        selectedText.diagonalCenter &&
        selectedText.diagonalCenter === cycle.diagonalCenter
      ) {
        const diagonalCenter = Convert.toPixel(
          selectedText.diagonalCenter,
          getCanvasParams()
        );
        const position = selectedText.getPosition(cycle, diagonalCenter);
        ctx.save();
        ctx.lineWidth = 2;
        ctx.strokeStyle = colors.selected;
        ctx.strokeRect(
          position.x,
          position.y,
          selectedText.width,
          selectedText.height +
            (cycle?.objTitle?.length ? selectedText.height : 0)
        );
        ctx.restore();
      }
    };

    const patternCanvas = document.createElement("canvas");
    const patternContext = patternCanvas.getContext("2d");

    const drawWall = (wall) => {
      if (!wall.isShow && mode !== "union") return;
      const { main, estimated, selectedStroke, selected } = colors;

      patternCanvas.width = Math.max(50 * zoom, 1);
      patternCanvas.height = Math.max(50 * zoom, 1);

      patternContext.fillStyle = main.patternBG;
      patternContext.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
      patternContext.strokeStyle = main.hatch;

      if (showEstimated && wall.estimate && wall.estimate.length === 0) {
        patternContext.fillStyle = estimated.patternBG;
      }

      ctx.lineWidth = 1;
      const colorWall =
        wallConstants?.optionsFillWall?.[wall?.planMaterial]?.color?.arbg ||
        Object.values(wallConstants.optionsFillWall).sort(
          (a, b) => (a.sort || Infinity) - (b.sort || Infinity)
        )[0].color.arbg;
      ctx.fillStyle = `rgba(${colorWall.r},${colorWall.g},${colorWall.b},${colorWall.a})`;
      ctx.strokeStyle = ctx.fillStyle;

      if (showEstimated && wall.estimate && wall.estimate.length === 0) {
        ctx.fillStyle = estimated.wall;
        ctx.strokeStyle = estimated.wallStroke;
      }

      if (!wall.isShow && mode === "union") {
        patternContext.strokeStyle = main.point;
        patternContext.lineWidth = 10 * zoom;
        patternContext.moveTo(0, 0);
        patternContext.lineTo(patternCanvas.width, patternCanvas.height);
        patternContext.moveTo(patternCanvas.width, 0);
        patternContext.lineTo(0, patternCanvas.height);
        patternContext.stroke();
        patternContext.strokeStyle = main.hatch;

        const pattern = patternContext.createPattern(patternCanvas, "repeat");
        const matrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);

        pattern.setTransform(matrix.translateSelf(-offsetX, -offsetY, 0));

        ctx.fillStyle = pattern;
      }

      if (
        activeObject !== null &&
        activeObject?.isWall &&
        activeObject.mainLink === wall.mainLink
      ) {
        ctx.fillStyle = selected;
        ctx.strokeStyle = selectedStroke;
      }

      if (
        activeObject !== null &&
        activeObject?.isPolygonWall &&
        wall === activeObject
      ) {
        ctx.fillStyle = selected;
        ctx.strokeStyle = selectedStroke;
      }

      if (wall?.isBezier) {
        const a = Convert.toPixel(wall.nodes[0], getCanvasParams());
        const b = Convert.toPixel(wall.nodes[1], getCanvasParams());
        const c = Convert.toPixel(wall.nodes[2], getCanvasParams());
        const d = Convert.toPixel(wall.nodes[3], getCanvasParams());
        const e = Convert.toPixel(wall.nodes[4], getCanvasParams());
        const f = Convert.toPixel(wall.nodes[5], getCanvasParams());

        const control_1A = Convert.toPixel(
          wall.bezierControlPoint_1A,
          getCanvasParams()
        );
        const control_1B = Convert.toPixel(
          wall.bezierControlPoint_1B,
          getCanvasParams()
        );

        const offsetBezierStart = Convert.toPixel(
          wall.outlineBezier[0].points[0],
          getCanvasParams()
        );
        const offsetBezierEnd = Convert.toPixel(
          wall.outlineBezier[wall.outlineBezier.length - 1].points[3],
          getCanvasParams()
        );

        const inlineBezierStart = Convert.toPixel(
          wall.inlineBezier[0].points[0],
          getCanvasParams()
        );
        const inlineBezierEnd = Convert.toPixel(
          wall.inlineBezier[wall.inlineBezier.length - 1].points[3],
          getCanvasParams()
        );

        ctx.beginPath();

        // "Закрытие" стен кривой
        ctx.moveTo(a.x, a.y);
        ctx.lineTo(c.x, c.y);
        ctx.lineTo(offsetBezierStart.x, offsetBezierStart.y);
        ctx.lineTo(a.x, a.y);
        ctx.fill();

        ctx.moveTo(b.x, b.y);
        ctx.lineTo(d.x, d.y);
        ctx.lineTo(offsetBezierEnd.x, offsetBezierEnd.y);
        ctx.lineTo(b.x, b.y);
        ctx.fill();

        ctx.moveTo(a.x, a.y);
        ctx.lineTo(e.x, e.y);
        ctx.lineTo(inlineBezierStart.x, inlineBezierStart.y);
        ctx.lineTo(a.x, a.y);
        ctx.fill();

        ctx.moveTo(b.x, b.y);
        ctx.lineTo(f.x, f.y);
        ctx.lineTo(inlineBezierEnd.x, inlineBezierEnd.y);
        ctx.lineTo(b.x, b.y);
        ctx.fill();

        // Толщина стены. Задаётся параллельным переносом основной кривой
        ctx.moveTo(a.x, a.y);

        wall.outlineBezier.forEach((bezier, index) => {
          const bezier_1A = Convert.toPixel(
            bezier.points[0],
            getCanvasParams()
          );
          const bezier_2B = Convert.toPixel(
            bezier.points[1],
            getCanvasParams()
          );
          const bezier_3C = Convert.toPixel(
            bezier.points[2],
            getCanvasParams()
          );
          const bezier_4D = Convert.toPixel(
            bezier.points[3],
            getCanvasParams()
          );

          ctx.lineTo(bezier_1A.x, bezier_1A.y);
          ctx.bezierCurveTo(
            bezier_2B.x,
            bezier_2B.y,
            bezier_3C.x,
            bezier_3C.y,
            bezier_4D.x,
            bezier_4D.y
          );
          ctx.stroke();
        });

        [...wall.inlineBezier].reverse().forEach((bezier, index) => {
          const bezier_1A = Convert.toPixel(
            bezier.points[3],
            getCanvasParams()
          );
          const bezier_2B = Convert.toPixel(
            bezier.points[2],
            getCanvasParams()
          );
          const bezier_3C = Convert.toPixel(
            bezier.points[1],
            getCanvasParams()
          );
          const bezier_4D = Convert.toPixel(
            bezier.points[0],
            getCanvasParams()
          );

          ctx.lineTo(bezier_1A.x, bezier_1A.y);
          ctx.bezierCurveTo(
            bezier_2B.x,
            bezier_2B.y,
            bezier_3C.x,
            bezier_3C.y,
            bezier_4D.x,
            bezier_4D.y
          );
          ctx.stroke();
        });
        ctx.fill();
        ctx.closePath();

        // Манипуляторы для изменения кривой
        ctx.lineWidth = 1;
        ctx.moveTo(a.x, a.y);
        ctx.lineTo(control_1A.x, control_1A.y);
        ctx.moveTo(b.x, b.y);
        ctx.lineTo(control_1B.x, control_1B.y);
        ctx.stroke();

        wall.mainLink.controlA.isControl = true;
        wall.mainLink.controlB.isControl = true;
      } else if (wall.isPolygonWall) {
        ctx.beginPath();
        wall.nodes.forEach((n, index) => {
          const node = Convert.toPixel(n, getCanvasParams());
          if (index === 0) {
            ctx.moveTo(node.x, node.y);
          } else {
            ctx.lineTo(node.x, node.y);
          }
          if (index === wall.nodes.length - 1) {
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
          }
        });
      } else {
        const a = Convert.toPixel(wall.nodes[4], getCanvasParams());
        const b = Convert.toPixel(wall.nodes[5], getCanvasParams());
        const c = Convert.toPixel(wall.nodes[0], getCanvasParams());
        const d = Convert.toPixel(wall.nodes[1], getCanvasParams());
        const e = Convert.toPixel(wall.nodes[2], getCanvasParams());
        const f = Convert.toPixel(wall.nodes[3], getCanvasParams());

        if (wall.mainLink?.controlA) {
          wall.mainLink.controlA.isControl = false;
        }
        if (wall.mainLink?.controlB) {
          wall.mainLink.controlB.isControl = false;
        }

        if (mode === "union") ctx.globalAlpha = 0.3;
        ctx.beginPath();
        ctx.moveTo(a.x, a.y);
        ctx.lineTo(b.x, b.y);
        ctx.lineTo(d.x, d.y);
        ctx.lineTo(f.x, f.y);
        ctx.lineTo(e.x, e.y);
        ctx.lineTo(c.x, c.y);
        ctx.lineTo(a.x, a.y);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        if (mode === "union") ctx.globalAlpha = 1;

        if (mode === "union") {
          drawNode(wall.innerLink.a);
          drawNode(wall.innerLink.b);
        }
      }

      if (
        wall.objTitle !== "" ||
        wall.objComment !== "" ||
        wall.objImages.length > 0
      ) {
        const _a = Convert.toPixel(wall.parallelLink.a, getCanvasParams());
        const _b = Convert.toPixel(wall.mainLink.b, getCanvasParams());
        const point = _b.clone().add(_a).multiplyScalar(0.5);

        infos.push({
          type: "wall",
          x: point.x,
          y: point.y,
        });
      }
    };

    const drawRuler = (ruler) => {
      const length = Math.round(
        new Vector2(ruler.a.x, ruler.a.y).distanceTo(
          new Vector2(ruler.b.x, ruler.b.y)
        )
      ).toString();

      const ln = Math.abs(ruler.a.clone().sub(ruler.b).length());
      const tw = ctx.measureText(length).width + 8;

      // if(ln*zoom>tw+30) {}

      ctx.font = "10px Arial";

      const _a = Convert.toPixel(ruler.a, getCanvasParams());
      const _b = Convert.toPixel(ruler.b, getCanvasParams());

      const v = _b.clone().sub(_a); //вектор стены
      const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
      const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
      const len = v.length();

      if (
        ruler.objTitle !== "" ||
        ruler.objComment !== "" ||
        ruler.objImages.length > 0
      ) {
        const __v = v.clone().setLength(ln / 2 - 50 * zoom);
        const __point = { x: __v.x + ruler.a.x, y: __v.y + ruler.a.y };
        const point = Convert.toPixel(__point, getCanvasParams());

        infos.push({
          type: "ruler",
          x: point.x,
          y: point.y,
        });
      }

      ctx.strokeStyle = ctx.fillStyle = "#FFB800";
      ctx.lineWidth = 1;
      if (
        (hoverObject &&
          hoverObject.a === ruler.a &&
          hoverObject.b === ruler.b) ||
        (activeObject &&
          activeObject.a === ruler.a &&
          activeObject.b === ruler.b)
      ) {
        ctx.strokeStyle = ctx.fillStyle = "#59DA28";
      }

      let halfH = -20;
      ctx.translate(c.x, c.y);
      ctx.rotate(a);
      ctx.beginPath();
      ctx.moveTo(-len / 2, -halfH - 17);
      ctx.lineTo(-len / 2, -halfH - 23);

      ctx.moveTo(len / 2, -halfH - 17);
      ctx.lineTo(len / 2, -halfH - 23);

      ctx.moveTo(-len / 2, -halfH - 20);
      ctx.lineTo(len / 2, -halfH - 20);
      ctx.stroke();

      if (ruler.showCircle) {
        ctx.beginPath();
        ctx.arc(-len / 2, -halfH - 20, len, 0, 2 * Math.PI);
        ctx.stroke();
      }

      halfH = -10;

      ctx.translate(0, -8 - halfH);
      ctx.fillRect(-tw / 2, -8 - 1, tw, 16);
      ctx.strokeRect(-tw / 2, -8 - 1, tw, 16);
      if (a > Math.PI / 2 && a < (3 * Math.PI) / 2) ctx.rotate(Math.PI);
      ctx.fillStyle = "#FFFFFF";
      if (a > Math.PI / 2 && a < (3 * Math.PI) / 2) ctx.fillText(length, 0, 2);
      else ctx.fillText(length, 0, 0);
      if (a > Math.PI / 2 && a < (3 * Math.PI) / 2) ctx.rotate(-Math.PI);
      ctx.translate(0, 8 + halfH);

      ctx.rotate(-a);
      ctx.translate(-c.x, -c.y);
    };

    const drawLink = (link) => {
      const _a = Convert.toPixel(link.a, getCanvasParams());
      const _b = Convert.toPixel(link.b, getCanvasParams());

      ctx.lineWidth = 1;
      ctx.fillStyle = "#000000";
      ctx.strokeStyle = "#000000";

      if (
        (hoverObject && hoverObject.a === link.a && hoverObject.b === link.b) ||
        (activeObject && activeObject.a === link.a && activeObject.b === link.b)
      ) {
        ctx.strokeStyle = ctx.fillStyle = "#59DA28";
      }

      ctx.beginPath();
      ctx.moveTo(_a.x, _a.y);
      ctx.lineTo(_b.x, _b.y);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    };

    const drawLeader = (leader) => {
      ctx.lineWidth = 1;
      ctx.fillStyle = "#000000";
      ctx.strokeStyle = "#000000";

      if (
        activeObject !== null &&
        activeObject.isLeader &&
        activeObject === leader
      ) {
        ctx.fillStyle = "#59DA28";
        ctx.strokeStyle = "#3ea516";
      }

      const a = Convert.toPixel(leader.a, getCanvasParams());
      const b = Convert.toPixel(leader.b, getCanvasParams());

      ctx.beginPath();
      ctx.moveTo(a.x, a.y);
      ctx.lineTo(b.x, b.y);

      const angle = Math.atan2(b.y - a.y, b.x - a.x);
      const headLength = 100 * zoom;

      ctx.moveTo(a.x, a.y);
      ctx.lineTo(
        a.x + headLength * Math.cos(angle - Math.PI / 12),
        a.y + headLength * Math.sin(angle - Math.PI / 12)
      );
      ctx.lineTo(
        a.x + headLength * Math.cos(angle + Math.PI / 12),
        a.y + headLength * Math.sin(angle + Math.PI / 12)
      );
      ctx.lineTo(a.x, a.y);
      ctx.fill();

      ctx.stroke();

      ctx.lineWidth = 2;
      const lineLength = 1000 * zoom;
      ctx.beginPath();
      ctx.moveTo(b.x, b.y);
      ctx.lineTo(b.x + lineLength, b.y);
      ctx.stroke();

      if (leader?.leaderText) {
        const textSize = 70 * zoom;
        const textX = b.x + lineLength / 2;
        const textY = b.y - textSize;

        function drawTextAboveLine(ctx, text, x, y, maxWidth) {
          const words = text.split(" ");
          let line = "";
          const lineHeight = textSize;

          let lines = [];

          for (let i = 0; i < words.length; i++) {
            const testLine = line + words[i] + " ";
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && i > 0) {
              lines.push(line);
              line = words[i] + " ";
            } else {
              line = testLine;
            }
          }
          lines.push(line);

          for (let i = lines.length - 1; i >= 0; i--) {
            ctx.fillText(lines[i], x, y);
            y -= lineHeight;
          }
        }

        ctx.font = `${textSize}px Arial`;
        ctx.textAlign = "center";
        drawTextAboveLine(ctx, leader.leaderText, textX, textY, lineLength);
      }

      ctx.lineWidth = 1;
    };

    const drawFigure = (link) => {
      ctx.lineWidth = 1;
      ctx.fillStyle = "#000000";
      ctx.strokeStyle = "#000000";

      if (
        activeObject !== null &&
        activeObject.isFigure &&
        activeObject === link
      ) {
        ctx.fillStyle = "#59DA28";
        ctx.strokeStyle = "#3ea516";
      }

      const a = Convert.toPixel(link.a, getCanvasParams());
      const b = Convert.toPixel(link.b, getCanvasParams());

      ctx.beginPath();
      ctx.setLineDash([5]);
      ctx.moveTo(a.x, a.y);
      ctx.lineTo(b.x, b.y);
      ctx.stroke();

      ctx.setLineDash([]);
    };

    const drawInfos = (point) => {
      ctx.fillStyle = "#28b6da90";
      ctx.strokeStyle = "#ffffff90";
      ctx.font = "14px Arial";

      ctx.lineWidth = 1;
      ctx.beginPath();
      const r = 80 * zoom;

      ctx.arc(point.x, point.y, r, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();

      ctx.fillStyle = "#fff";
      ctx.fillText("i", point.x, point.y);
    };

    const drawNode = (n) => {
      ctx.fillStyle = "#ffffff";
      ctx.strokeStyle = "#A1A1A1";

      if (n.isLocked) {
        ctx.fillStyle = "#ffffff70";
        ctx.strokeStyle = "#A1A1A170";
      }
      if (activeObject && n === activeObject.object) {
        ctx.fillStyle = "#59DA28";
        ctx.strokeStyle = "#3ea516";
      }

      ctx.fillStyle = "#F00";
      ctx.strokeStyle = "#F00";

      const _n = Convert.toPixel(n, getCanvasParams());
      ctx.lineWidth = 1.5;
      ctx.beginPath();
      const r = Math.min(13, 8 / zoom);

      if (mode === "union") {
        ctx.fillStyle = "#da6628";
        ctx.strokeStyle = "#a55216";
      }

      if (n.isControl) {
        ctx.fillStyle = "#daa228";
        ctx.strokeStyle = "#a57316";
      }

      if (n.isPolygonWall) {
        ctx.fillStyle = "#2860da";
        ctx.strokeStyle = "#1637a5";
      }

      ctx.arc(_n.x, _n.y, r * zoom, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();

      if ((n.isFigure || n.isWall) && filters.angle) {
        drawAngles(n);
      }
    };

    const drawAngles = (n) => {
      const links = [];
      let node = n;
      let mainNode = n;
      let skipRender = false;

      if (n.isWall) {
        plan.bWalls.forEach((w) => {
          if (w.mainLink.a.x === n.x && w.mainLink.a.y === n.y) {
            node = w.innerLink.a;
            links.push(w.innerLink);
          } else if (w.mainLink.b.x === n.x && w.mainLink.b.y === n.y) {
            node = w.innerLink.b;
            links.push(w.innerLink);
          }
        });
      }
      if (n.isFigure) {
        plan.links.forEach((l) => {
          if (
            (l.a.x === n.x && l.a.y === n.y) ||
            (l.b.x === n.x && l.b.y === n.y)
          ) {
            links.push(l);
          }
        });
      }

      if (links.length >= 2) {
        const _n = Convert.toPixel(node, getCanvasParams());

        links.sort((a, b) => {
          let v1;
          if (a.a.x === node.x && a.a.y === node.y) {
            v1 = a.a
              .clone()
              .sub(a.b)
              .negate()
              .normalize()
              .setLength(50 * zoom);
          } else if (a.b.x === node.x && a.b.y === node.y) {
            v1 = a.b
              .clone()
              .sub(a.a)
              .negate()
              .normalize()
              .setLength(50 * zoom);
          }
          let v2;
          if (b.a.x === node.x && b.a.y === node.y) {
            v2 = b.a
              .clone()
              .sub(b.b)
              .negate()
              .normalize()
              .setLength(50 * zoom);
          } else if (b.b.x === node.x && b.b.y === node.y) {
            v2 = b.b
              .clone()
              .sub(b.a)
              .negate()
              .normalize()
              .setLength(50 * zoom);
          }

          if (!v1 || !v2) {
            skipRender = true;
            return true;
          }

          return v2.angle() - v1.angle();
        });

        if (skipRender) return;

        let prevLinks = links[links.length - 1];

        links.forEach((link) => {
          let _b1r, _a1, v1;
          if (prevLinks.a.x === node.x && prevLinks.a.y === node.y) {
            v1 = prevLinks.a
              .clone()
              .sub(prevLinks.b)
              .negate()
              .normalize()
              .setLength(50 * zoom);
            _a1 = Convert.toPixel(prevLinks.a, getCanvasParams());
          } else if (prevLinks.b.x === node.x && prevLinks.b.y === node.y) {
            v1 = prevLinks.b
              .clone()
              .sub(prevLinks.a)
              .negate()
              .normalize()
              .setLength(50 * zoom);
            _a1 = Convert.toPixel(prevLinks.b, getCanvasParams());
          }
          _b1r = { x: v1.x + _a1.x, y: v1.y + _a1.y };

          let _b2r, _a2, v2;
          if (link.a.x === node.x && link.a.y === node.y) {
            v2 = link.a
              .clone()
              .sub(link.b)
              .negate()
              .normalize()
              .setLength(50 * zoom);
            _a2 = Convert.toPixel(link.a, getCanvasParams());
          } else if (link.b.x === node.x && link.b.y === node.y) {
            v2 = link.b
              .clone()
              .sub(link.a)
              .negate()
              .normalize()
              .setLength(50 * zoom);
            _a2 = Convert.toPixel(link.b, getCanvasParams());
          }
          _b2r = { x: v2.x + _a2.x, y: v2.y + _a2.y };

          let _b4r, _b3r, v3, v4;
          v3 = new Node(_b1r.x, _b1r.y)
            .sub(new Node(_b2r.x, _b2r.y))
            .multiplyScalar(0.5);
          _b3r = { x: v3.x + _b2r.x, y: v3.y + _b2r.y };

          const angleA = v1.angle();
          const angleB = v2.angle();

          let angle;

          if (angleA > angleB) {
            angle = parseFloat(
              ((angleA - angleB) * (180 / Math.PI)).toFixed(1)
            );
          } else {
            angle = parseFloat(
              ((2 * Math.PI - (angleB - angleA)) * (180 / Math.PI)).toFixed(1)
            );
          }

          const arcR = Math.min(100 * zoom, 100 / zoom);
          const textR = Math.min(150 * zoom, 150 / zoom);

          if (angle >= 180) {
            v4 = new Node(_n.x, _n.y)
              .sub(new Node(_b3r.x, _b3r.y))
              .setLength(textR);
          } else {
            v4 = new Node(_n.x, _n.y)
              .sub(new Node(_b3r.x, _b3r.y))
              .setLength(textR)
              .negate();
          }

          if (angle >= 180) {
            prevLinks = link;
            return;
          }

          _b4r = { x: v4.x + _n.x, y: v4.y + _n.y };

          ctx.fillStyle = "#000000";
          ctx.font = "10px Arial";
          ctx.fillText(angle + "°", _b4r.x, _b4r.y);

          ctx.strokeStyle = "#FFB800";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.arc(_n.x, _n.y, arcR, v2.angle(), v1.angle());
          ctx.stroke();

          if (angles?.current === `${node.x},${node.y}`) {
            onChange({ position: _b4r });
          }

          angles[`${node.x},${node.y}`] = {
            ...(angles[`${node.x},${node.y}`]
              ? angles[`${node.x},${node.y}`]
              : {}),
            angle,
            position: _b4r,
            links: [link, prevLinks],
          };

          prevLinks = link;
        });
      }
    };

    const drawModule = (module) => {
      const pos = Convert.toPixel(
        new Vector2(module.position.x, module.position.y),
        getCanvasParams()
      );

      ctx.translate(pos.x, pos.y);
      ctx.rotate(-module.angle);
      // ctx.scale(zoom,zoom);
      const moduleImage =
        module === hoverObject ? module.shema.hover : module.shema.normal;

      const imageW = (moduleImage.width / 2) * zoom * 10;
      const imageH = (moduleImage.height / 2) * zoom * 10;
      let moduleX, moduleY;
      // moduleX = ((-module.size.pivot.x/ratio*zoom) + (module.size.width/2/ratio*zoom) - imageW/2)
      // moduleY = ((-module.size.pivot.y/ratio*zoom) + (module.size.height/2/ratio*zoom) - imageH/2)
      moduleX =
        -module.size.pivot.x * zoom +
        (module.size.width / 2) * zoom -
        imageW / 2;
      moduleY =
        -module.size.pivot.y * zoom +
        (module.size.height / 2) * zoom -
        imageH / 2;
      // if (module._optionStatus)
      //     moduleY = ((-module.size.pivot.y) + (module.size.height/2) - imageH/2) - (41)

      if (
        module.objTitle !== "" ||
        module.objComment !== "" ||
        module.objImages.length > 0
      ) {
        infos.push({
          type: "module",
          x: pos.x,
          y: pos.y - 20 * zoom,
        });
      }

      //Тень
      ctx.beginPath();
      ctx.strokeStyle = "#eeeeee50";
      ctx.fillStyle = "#eeeeee50";
      ctx.lineWidth = 50 * zoom;
      ctx.moveTo(-module.size.pivot.x * zoom, -module.size.pivot.y * zoom);
      ctx.lineTo(
        (module.size.width - module.size.pivot.x) * zoom,
        -module.size.pivot.y * zoom
      );
      ctx.lineTo(
        (module.size.width - module.size.pivot.x) * zoom,
        (module.size.height - module.size.pivot.y) * zoom
      );
      ctx.lineTo(
        -module.size.pivot.x * zoom,
        (module.size.height - module.size.pivot.y) * zoom
      );
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
      ctx.lineWidth = 1;

      if (window.debug) {
        ctx.fillStyle = "#67889720";
        ctx.fillRect(moduleX, moduleY, imageW, imageH);
      }
      ctx.drawImage(
        //рисуется обекъек и цвет
        moduleImage,
        moduleX,
        moduleY,
        imageW,
        imageH
      );

      if (showEstimated && module.estimate.length === 0) {
        ctx.beginPath();
        ctx.strokeStyle = colors.estimated.module;
        ctx.fillStyle = colors.estimated.module;
        ctx.lineWidth = 50 * zoom;
        ctx.moveTo(-module.size.pivot.x * zoom, -module.size.pivot.y * zoom);
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom,
          -module.size.pivot.y * zoom
        );
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom,
          (module.size.height - module.size.pivot.y) * zoom
        );
        ctx.lineTo(
          -module.size.pivot.x * zoom,
          (module.size.height - module.size.pivot.y) * zoom
        );
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.lineWidth = 1;
      }

      // console.log('ratio',ratio)
      // console.log('drawModule():module',module)
      // console.log('moduleImage.width, moduleImage.height',moduleImage.width, moduleImage.height)

      if (activeObject === module) {
        // ctx.scale(1/zoom,1/zoom);
        //периметр
        ctx.beginPath();
        ctx.strokeStyle = "#555555";
        ctx.fillStyle = "#555555";
        ctx.moveTo(-module.size.pivot.x * zoom, -module.size.pivot.y * zoom);
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom,
          -module.size.pivot.y * zoom
        );
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom,
          (module.size.height - module.size.pivot.y) * zoom
        );
        ctx.lineTo(
          -module.size.pivot.x * zoom,
          (module.size.height - module.size.pivot.y) * zoom
        );
        ctx.closePath();
        ctx.stroke();

        ctx.strokeStyle = ctx.fillStyle = "#59DA2880";
        ctx.lineWidth = 160 * zoom;
        ctx.beginPath();
        ctx.moveTo(
          -module.size.pivot.x * zoom - 80 * zoom,
          -module.size.pivot.y * zoom - 80 * zoom
        );
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom + 80 * zoom,
          -module.size.pivot.y * zoom - 80 * zoom
        );
        ctx.lineTo(
          (module.size.width - module.size.pivot.x) * zoom + 80 * zoom,
          (module.size.height - module.size.pivot.y) * zoom + 80 * zoom
        );
        ctx.lineTo(
          -module.size.pivot.x * zoom - 80 * zoom,
          (module.size.height - module.size.pivot.y) * zoom + 80 * zoom
        );
        ctx.closePath();
        ctx.stroke();
        ctx.lineWidth = 1;

        // Pivot
        if (module?.showSizeButtons) {
          const r = Math.min(12, 56 / zoom);
          ctx.beginPath();
          ctx.fillStyle = "#222222";
          ctx.strokeStyle = "#ffffff";
          ctx.lineWidth = 1;
          ctx.arc(
            (module.size.pivot.x - module.size.width - 10) * zoom - r / 2,
            (module.size.pivot.y - module.size.height - 10) * zoom - r / 2,
            r,
            0,
            Math.PI * 2
          );
          ctx.fill();
          ctx.stroke();

          if (plan.icons?.rotate) {
            ctx.drawImage(
              plan.icons.rotate,
              (module.size.pivot.x - module.size.width - 10) * zoom - r,
              (module.size.pivot.y - module.size.height - 10) * zoom - r,
              r,
              r
            );
          }
        }

        //дистанции
        let d; //дистанция
        let origin = new Vector2(); // источник луча
        let direction = new Vector2(); // направление луча
        const zeroVector = new Vector2(0, 0);
        let w = module.size.width;
        let h = module.size.height;
        let pivot = module.size.pivot;
        let mod = module.model;
        let angle = module.angle;

        const drawRay = (directionSide) => {
          d = Infinity;
          let ctxTranslateX;
          let ctxTranslateY;
          let ctxRotate;
          switch (directionSide) {
            case "up": {
              origin.x = 0;
              origin.y = -pivot.y;
              direction.x = 0;
              direction.y = -1;
              ctx.strokeStyle = "#7e5c52";
              ctx.fillStyle = "#7e5c52";
              break;
            }
            case "bottom": {
              origin.x = 0;
              origin.y = h - pivot.y;
              direction.x = 0;
              direction.y = 1;
              ctx.strokeStyle = "#e5c100";
              ctx.fillStyle = "#e5c100";
              break;
            }
            case "left": {
              origin.x = -pivot.x;
              origin.y = 0;
              direction.x = -1;
              direction.y = 0;
              ctx.strokeStyle = "#F56600";
              ctx.fillStyle = "#F56600";
              break;
            }
            case "right": {
              origin.x = w - pivot.x;
              origin.y = 0;
              direction.x = 1;
              direction.y = 0;
              ctx.strokeStyle = "#6082B6";
              ctx.fillStyle = "#6082B6";
              break;
            }
            default: {
              break;
            }
          }

          origin.rotateAround(zeroVector, -angle);
          origin.add(new Vector2(module.position.x, module.position.y));
          direction.rotateAround(zeroVector, -angle);

          const checkDotEntersRoom = (point, cycles) => {
            //проверяем что точка входи в выбраную комнату
            const x = point.x;
            const y = point.y;

            let inside = false;
            for (
              let i = 0, j = cycles._points.length - 1;
              i < cycles._points.length;
              j = i++
            ) {
              let xi = cycles._points[i].x,
                yi = cycles._points[i].y;
              let xj = cycles._points[j].x,
                yj = cycles._points[j].y;
              let intersect =
                yi > y !== yj > y &&
                x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
              if (intersect) {
                inside = !inside;
              }
            }
            return inside;
          };

          for (let c = 0; plan.cycles.length > c; c++) {
            if (checkDotEntersRoom(origin, plan.cycles[c])) {
              for (let i = 0; plan.cycles[c]._links.length > i; i++) {
                for (let j = 0; plan.bWalls.length > j; j++) {
                  if (
                    checkMatchNode(
                      plan.cycles[c]._links[i].a,
                      plan.bWalls[j].mainLink.a
                    ) &&
                    checkMatchNode(
                      plan.cycles[c]._links[i].b,
                      plan.bWalls[j].mainLink.b
                    )
                  ) {
                    const d2w = distanceRayToWall(
                      origin,
                      direction,
                      plan.bWalls[j]
                    );
                    if (d2w < d) {
                      d = d2w;
                    }
                  }
                }
              }
            }
          }

          props.modules.forEach((m) => {
            if (m !== module) {
              const _d = distanceRayToModule(origin, direction, m);
              if (_d < d) d = _d;
            }
          });

          switch (directionSide) {
            case "up": {
              ctxTranslateX = 0;
              ctxTranslateY = -d / 2 - pivot.y;
              ctxRotate = -Math.PI / 2;
              break;
            }
            case "bottom": {
              ctxTranslateX = 0;
              ctxTranslateY = d / 2 + h - pivot.y;
              ctxRotate = -Math.PI / 2;
              break;
            }
            case "left": {
              ctxTranslateX = -d / 2 - pivot.x;
              ctxTranslateY = 0;
              ctxRotate = 0;
              break;
            }
            case "right": {
              ctxTranslateX = d / 2 + w - pivot.x;
              ctxTranslateY = 0;
              ctxRotate = 0;
              break;
            }
            default: {
              break;
            }
          }

          if (d !== Infinity && d > 0) {
            d = Math.round(d);
            const tw = ctx.measureText(d.toString()).width + 8;
            ctx.translate(ctxTranslateX * zoom, ctxTranslateY * zoom);
            ctx.rotate(ctxRotate);
            ctx.beginPath();
            ctx.moveTo((-d * zoom) / 2, 0);
            ctx.lineTo((d * zoom) / 2, 0);
            ctx.stroke();
            ctx.fillRect(-tw / 2, -8, tw, 16);
            ctx.strokeStyle = "#FFFFFF";
            ctx.fillStyle = "#FFFFFF";
            ctx.fillText(d, 0, 0);
            ctx.rotate(-ctxRotate);
            ctx.translate(-ctxTranslateX * zoom, -ctxTranslateY * zoom);
            locationInRooms[directionSide] = d;
          }
        };
        const locationInRooms = {
          up: null,
          right: null,
          bottom: null,
          left: null,
        };

        drawRay("up");
        drawRay("right");
        drawRay("bottom");
        drawRay("left");

        // dispatch(projectState.setLocationInRooms(locationInRooms));
        module.locationInRooms = locationInRooms;
      }
      // else ctx.scale(1/zoom,1/zoom);
      ctx.rotate(module.angle);
      ctx.translate(-pos.x, -pos.y);
    };

    const removeColumn = () => {
      if (tool === "columns") {
        const cid = plan.columns.length;
        plan.bWalls.forEach((wall) => {
          wall.removeColumns(cid);
        });
        plan.virtualColumn = null;
        setMoveTool();
      }
    };

    const handlerKeydown = (event) => {
      if (plan.mode === "ruler") return;

      if (
        activeObject &&
        activeObject.type === "node" &&
        !activeObject.object.isLocked
      ) {
        if (event.code === "ArrowUp") {
          activeObject.object.y -= steps;
        } else if (event.code === "ArrowDown") {
          activeObject.object.y += steps;
        } else if (event.code === "ArrowLeft") {
          activeObject.object.x -= steps;
        } else if (event.code === "ArrowRight") {
          activeObject.object.x += steps;
        }
        draw(ctx, plan);
      } else if (activeObject && activeObject.isModule) {
        let vPos;
        if (event.code === "ArrowUp") {
          vPos = activeObject.position
            .clone()
            .sub(
              new Vector2(
                activeObject.position.x,
                activeObject.position.y - steps
              )
            );

          activeObject.position = {
            x: activeObject.position.x - vPos.x,
            y: activeObject.position.y - vPos.y,
          };
        } else if (event.code === "ArrowDown") {
          vPos = activeObject.position
            .clone()
            .sub(
              new Vector2(
                activeObject.position.x,
                activeObject.position.y + steps
              )
            );

          activeObject.position = {
            x: activeObject.position.x - vPos.x,
            y: activeObject.position.y - vPos.y,
          };
        } else if (event.code === "ArrowLeft") {
          vPos = activeObject.position
            .clone()
            .sub(
              new Vector2(
                activeObject.position.x - steps,
                activeObject.position.y
              )
            );

          activeObject.position = {
            x: activeObject.position.x - vPos.x,
            y: activeObject.position.y - vPos.y,
          };
        } else if (event.code === "ArrowRight") {
          vPos = activeObject.position
            .clone()
            .sub(
              new Vector2(
                activeObject.position.x + steps,
                activeObject.position.y
              )
            );

          activeObject.position = {
            x: activeObject.position.x - vPos.x,
            y: activeObject.position.y - vPos.y,
          };
        }
        _setActiveObject({
          object: activeObject,
        });
        draw(ctx, plan);
      }
      if (activeObject && activeObject.isCycle && activeObject.isFloor) {
        if (event.code === "ArrowUp") {
          selectedRoom.move(plan, modules, activeObject, 0, -steps);
        }
        if (event.code === "ArrowDown") {
          selectedRoom.move(plan, modules, activeObject, 0, steps);
        }
        if (event.code === "ArrowLeft") {
          selectedRoom.move(plan, modules, activeObject, -steps, 0);
        }
        if (event.code === "ArrowRight") {
          selectedRoom.move(plan, modules, activeObject, steps, 0);
        }
        draw(ctx, plan);
      }
    };

    const handlerKeyUp = (event) => {
      if (
        event.code === "ArrowUp" ||
        event.code === "ArrowDown" ||
        event.code === "ArrowLeft" ||
        event.code === "ArrowRight"
      ) {
        if (plan.mode === "ruler") return;

        if (activeObject && activeObject.isModule) {
          _setActiveObject({
            object: activeObject,
          });
          draw(ctx, plan);
        }
      }
    };

    const handlerDown = (event) => {
      setShowMenu(false);
      clickCanvas = true;

      if (event.type === "touchstart") {
        event.preventDefault();
      }

      const touches = event.touches || event.changedTouches;

      if (event.type === "touchstart" && touches.length === 2) {
        touchesZoom = Convert.toSM(
          new Node(
            (touches[0].clientX + touches[1].clientX) / 2,
            (touches[0].clientY + touches[1].clientY) / 2
          ),
          getCanvasParams()
        );

        scaling = true;
        lastDistance = Math.sqrt(
          (touches[0].clientX - touches[1].clientX) *
            (touches[0].clientX - touches[1].clientX) +
            (touches[0].clientY - touches[1].clientY) *
              (touches[0].clientY - touches[1].clientY)
        );

        return;
      }

      if (
        event.button === 2 ||
        (event.type === "touchstart" && touches.length === 2)
      ) {
        removeColumn();
      }

      scaling = false;

      let point, pointWindow;
      if (event.type === "touchstart") {
        if (event.changedTouches.length < 1) return;

        point = Convert.toSM(
          new Node(
            event.changedTouches[0].clientX,
            event.changedTouches[0].clientY
          ),
          getCanvasParams()
        );
        point.x = Math.round(point.x / 5) * 5;
        point.y = Math.round(point.y / 5) * 5;

        if (!scaling && !moving && !columnSizing) {
          timerRef.current = setTimeout(() => handlerContext(event), 500);
        }
        touchPrevEvent = event;
      } else {
        point = Convert.toSM(
          new Node(event.clientX, event.clientY),
          getCanvasParams()
        );

        point.x = Math.round(point.x / 5) * 5;
        point.y = Math.round(point.y / 5) * 5;

        pointWindow = new Node(event.clientX, event.clientY);
      }

      if (
        hoverObject &&
        (hoverObject.type === "ImageBG" ||
          hoverObject.type === "ImageBGpointA" ||
          hoverObject.type === "ImageBGpointB")
      ) {
        _setActiveObject({
          point,
          object: hoverObject,
        });
        clickObject = hoverObject;
        plan.clickObject = hoverObject;

        return;
      }

      if (
        !scaling &&
        (plan.mode === "polygonWalls" ||
          plan.mode === "walls" ||
          plan.mode === "ruler" ||
          plan.mode === "figures" ||
          plan.mode === "leader")
      ) {
        if (event.button === 2) {
          setActiveObject(null);
          plan.setActiveObject(null);
          if (plan.mode === "polygonWalls" && plan.virtualPolygonWall) {
            plan.closePolygonWall(distForMerge());
            setUndo({ type: "plan" });
          }
          setMoveTool();
        } else {
          const [_dist, _node] = plan.nodes.reduce(
            (dn, n) => {
              const _d = distNodeToNode(point, n);
              return _d < dn[0] ? [_d, n] : dn;
            },
            [Infinity, null]
          );
          if (_dist < distForMerge()) {
            point.x = _node.x;
            point.y = _node.y;
          }

          if (!plan.virtualPolygonWall) {
            if (plan.mode === "walls") {
              point.isWall = true;
            } else if (plan.mode === "polygonWalls") {
              point.isPolygonWall = true;
            } else if (plan.mode === "ruler") {
              point.isRuler = true;
            } else if (plan.mode === "figures") {
              point.isFigure = true;
            } else if (plan.mode === "leader") {
              point.isLeader = true;
            }

            plan.putNode(point);
          }

          plan.dragNode = plan.virtualNode = point.clone();

          if (plan.mode === "walls") {
            plan.virtualNode.isWall = true;
          } else if (plan.mode === "polygonWalls") {
            plan.virtualNode.isPolygonWall = true;
          } else if (plan.mode === "ruler") {
            plan.virtualNode.isRuler = true;
          } else if (plan.mode === "figures") {
            plan.virtualNode.isFigure = true;
          } else if (plan.mode === "leader") {
            plan.virtualNode.isLeader = true;
          }

          plan.putNode(plan.virtualNode);

          if (plan.mode === "walls") {
            plan.virtualLink = new Link(point, plan.virtualNode, "wall");
          } else if (plan.mode === "polygonWalls") {
            plan.virtualLink = new Link(
              plan?.virtualPolygonWall?.nodes.at(-1) || point,
              plan.virtualNode,
              "polygonWall"
            );
          } else if (plan.mode === "ruler") {
            plan.virtualLink = new Link(point, plan.virtualNode, "ruler");
          } else if (plan.mode === "figures") {
            plan.virtualLink = new Link(point, plan.virtualNode, "figure");
          } else if (plan.mode === "leader") {
            plan.virtualLink = new Link(point, plan.virtualNode, "leader");
          }

          if (plan.bWalls.length > 0) {
            plan.virtualLink.depth =
              plan.bWalls[plan.bWalls.length - 1].mainLink.depth;
            plan.virtualLink.height =
              plan.bWalls[plan.bWalls.length - 1].mainLink.height;
            plan.virtualLink.lrBuild =
              plan.bWalls[plan.bWalls.length - 1].mainLink.lrBuild;
          }
        }
      } else if (plan.mode === "move" || plan.mode === "view") {
        if (
          activeObject &&
          (activeObject.isModule || activeObject.isColumn) &&
          activeObject.isHoverRotateControl(point, zoom) &&
          plan.mode === "move" &&
          mode !== "union"
        ) {
          const position =
            activeObject.position || new Node(activeObject.x, activeObject.y);

          startAngle = activeObject.angle;
          rotate = true;
          clickMousePoint = point.clone();
          startPosition = position.clone();

          undoModulePrev = {
            angel: activeObject.angle,
            position: { x: position.x, y: position.y },
          };

          return;
        } else if (
          activeObject &&
          activeObject.isColumn &&
          activeObject.isHoverSizingControl(point, zoom) &&
          plan.mode === "move" &&
          mode !== "union"
        ) {
          const _columnSizing = activeObject.isHoverSizingControl(point, zoom);
          if (_columnSizing.status) {
            columnSizing = _columnSizing.status;
            columnSizingDirection = _columnSizing.direction;
            return;
          }
        } else if (
          activeObject &&
          activeObject.isModule &&
          plan.mode === "move" &&
          mode !== "union"
        ) {
          let clickObjectModule = null;
          props.modules.map((m) => {
            if (m.isHover(point)) clickObjectModule = m;
          });
          if (activeObject === clickObjectModule) {
            // Нажали на активный модуль
            moving = true;
            clickMousePoint = point.clone();
            startPosition = activeObject.position.clone();

            undoModulePrev = {
              angel: activeObject.angle,
              position: {
                x: activeObject.position.x,
                y: activeObject.position.y,
              },
            };

            return;
          }
        }

        if (
          activeObject &&
          activeObject.isColumn &&
          checkHoverColumn(activeObject, point)
        ) {
          isHoverColumn = true;
          startPosition = point;
        }

        let [_dist, _node] = plan.nodes.reduce(
          (dn, n) => {
            let _d;
            if (
              n.isLocked ||
              (!filters.walls && n.isWall) ||
              (!filters.walls && n.isPolygonWall) ||
              (!filters.ruler && n.isRuler) ||
              (!filters.floors && n.isFigure) ||
              (!filters.ruler && n.isLeader)
            ) {
              _d = Infinity;
            } else {
              if (mode === "union") {
                if (n.isPolygonWall) {
                  _d = distNodeToNode(point, n);
                }
              } else {
                _d = distNodeToNode(point, n);
              }
            }

            return _d < dn[0] ? [_d, n] : dn;
          },
          [Infinity, null]
        );

        // Если выбрана стена, её точки всегда сверху остальных
        if (activeObject !== null && activeObject.isWall) {
          [_dist, _node] = plan.nodes.reduce(
            (dn, n) => {
              let _d;
              if (activeObject.nodes.includes(n) || activeObject.isBezier) {
                _d = distNodeToNode(point, n);
              } else {
                _d = Infinity;
              }

              return _d < dn[0] ? [_d, n] : dn;
            },
            [Infinity, null]
          );
        }

        if (_dist < distForMerge() && !isHoverColumn) {
          if (mode === "union") {
            if (_node?.isPolygonWall) plan.dragNode = _node;
          } else {
            plan.dragNode = _node;
          }
        }

        if (!plan.dragNode) drag = true;
      }

      if (plan.mode === "move" && mode !== "union") {
        selectedRoom.ready2MoveFN(point, plan, modules);
        selectedText.checkIsReadyToMove(pointWindow || point);
      }

      if (plan.dragNode) {
        currentWall = plan.bWalls.find(
          (wall) =>
            wall.mainLink.a === plan.dragNode ||
            wall.mainLink.b === plan.dragNode
        );
      }

      const bgProps = getBGProps(getCanvasParams(), ImageBGData);
      if (
        ImageBGData.isEditing &&
        filters.imageBG &&
        ImageBGData.id &&
        getBGIntersect(bgProps, pointWindow)
      ) {
        ImageBGData.isDragging = true;
      }
      draw(ctx, plan);
    };

    const handlerUp = (event) => {
      currentWall = null;
      clearTimeout(timerRef.current);
      const prevActiveObject = activeObject;
      const pointWindow = new Node(event.clientX, event.clientY);

      if (!clickCanvas && mode !== "union") {
        clickCanvas = false;
        return;
      }
      clickCanvas = false;
      clickObject = null;
      ImageBGData.isDragging = false;

      const bgProps = getBGProps(getCanvasParams(), ImageBGData);
      if (
        ImageBGData.isEditing &&
        filters.imageBG &&
        ImageBGData.id &&
        getBGIntersect(bgProps, pointWindow)
      ) {
        ImageBGData.isHover = true;
      } else {
        ImageBGData.isHover = false;
      }

      if (!dragDistance && !scaling && !ImageBGData.isHover) {
        if (selectedRoom.ready2Move) {
          setUndo({ type: "plan" });
          selectedRoom.moveCancel();
          plan.setFloors("floor");
          plan.resetMagnetization();
          prevTouch = null;
        }

        if (selectedText.readyToMove || selectedText.diagonalCenter) {
          selectedText.cancelMove();
          dispatch(projectState.setModal(""));
          prevTouch = null;
        }

        let point, pointWindow;
        if (event.type === "touchend") {
          if (event.changedTouches.length < 1) return;
          point = Convert.toSM(
            new Node(
              event.changedTouches[0].clientX,
              event.changedTouches[0].clientY
            ),
            getCanvasParams()
          );
          pointWindow = new Node(
            event.changedTouches[0].clientX,
            event.changedTouches[0].clientY
          );
        } else {
          point = Convert.toSM(
            new Node(event.clientX, event.clientY),
            getCanvasParams()
          );
          pointWindow = new Node(event.clientX, event.clientY);
        }

        let click_node = null;
        const [_dist, _node] = plan.nodes.reduce(
          (dn, n) => {
            const _d = distNodeToNode(point, n);
            return _d < dn[0] ? [_d, n] : dn;
          },
          [Infinity, null]
        );
        if (_dist < distForMerge()) click_node = _node;

        if (tool === "columns") {
          if (!plan.virtualColumn) {
            plan.virtualColumn = new Column(point);

            plan.virtualColumn.depth = columnParams.width;
            plan.virtualColumn.width = columnParams.depth;
          } else {
            plan.virtualColumn.x = point.x;
            plan.virtualColumn.y = point.y;
          }

          const cid = plan.columns.length;
          const stickPoint = stickColumnToWall(
            point,
            plan.bWalls,
            plan.virtualColumn,
            cid,
            zoom
          );

          if (stickPoint) {
            const { x, y, angle, wallIndex, wallSide } = stickPoint;

            plan.virtualColumn.x = x;
            plan.virtualColumn.y = y;
            plan.virtualColumn.angle = angle;

            plan.virtualColumn.setParentWallID(wallIndex, wallSide);
            plan.bWalls[wallIndex].setColumns(cid, wallSide);
          }

          plan.columns.push(plan.virtualColumn);
          initColumnPoints(plan.virtualColumn);

          plan.virtualColumn = new Column(point);
          plan.virtualColumn.width = columnParams.width;
          plan.virtualColumn.depth = columnParams.depth;

          setUndo({ type: "plan" });
          plan.setFloors("floor");
          removeColumn();

          draw(ctx, plan);
          return;
        }

        if (
          plan.mode === "polygonWalls" ||
          plan.mode === "walls" ||
          plan.mode === "ruler" ||
          plan.mode === "figures" ||
          plan.mode === "leader"
        ) {
          if (plan.dragNode) {
            const [_dist, _node] = plan.nodes.reduce(
              (dn, n) => {
                let _d;
                if (plan.dragNode !== n) _d = distNodeToNode(plan.dragNode, n);
                else {
                  _d = Infinity;
                }
                return _d < dn[0] ? [_d, n] : dn;
              },
              [Infinity, null]
            );
            if (_dist < distForMerge()) {
              if (plan.virtualLink) {
                plan.virtualNode.x = _node.x;
                plan.virtualNode.y = _node.y;
                plan.virtualLink.b = plan.dragNode = plan.virtualNode;
              } else {
                plan.dragNode.x = _node.x;
                plan.dragNode.y = _node.y;
              }
            }
            let flagLeng0 = false;
            if (plan.virtualLink) {
              if (plan.mode === "ruler" || plan.mode === "leader") {
                plan.putRuller();
                if (
                  !checkMatchNode(
                    plan.links[plan.links.length - 1].a,
                    plan.links[plan.links.length - 1].b
                  )
                ) {
                  _setActiveObject({
                    point: plan.dragNode,
                    object: plan.virtualLink,
                  });
                } else {
                  plan.links.length = plan.links.length - 1;
                }
              } else if (
                plan.mode === "walls" ||
                plan.mode === "polygonWalls" ||
                plan.mode === "figures"
              ) {
                const wall =
                  plan.mode === "polygonWalls"
                    ? plan.putPolygonWall(distForMerge())
                    : plan.putWall();
                if (plan.mode !== "polygonWalls") {
                  plan.clearEmptyNodes();
                  if (
                    checkMatchNode(
                      plan.nodes[plan.nodes.length - 1],
                      plan.nodes[plan.nodes.length - 2]
                    )
                  ) {
                    plan.nodes.length = plan.nodes.length - 2;
                    flagLeng0 = true;
                    dispatch(projectState.setModal(""));
                  } else {
                    _setActiveObject({
                      point: plan.dragNode,
                      object: wall,
                    });
                  }
                }
              }
            }
            if (plan.dragNode.isWall) {
              plan.setFloors("floor");
            } else if (plan.dragNode.isFigure) {
              plan.setFloors("figures");
            }

            if (!flagLeng0 && mode !== "union") {
              setUndo({ type: "plan" });
            }
          }
        } else if (plan.mode === "move" || plan.mode === "view") {
          if (plan.dragNode && plan.dragNode?.isPolygonWall) {
            plan.calculatePolygonWalls();
          }

          if (activeObject && activeObject?.isColumn) {
            setUndo({ type: "plan" });
          }

          let clickColumn = null;
          let clickColumnObject = null;
          if (mode !== "union" && plan.mode !== "view") {
            plan.columns.forEach((column) => {
              if (checkHoverColumn(column, point)) {
                for (let i = 0; i < column?.objects?.length; i++) {
                  const obj = column?.objects?.[i];
                  if (obj && polyPoint(obj.points, point.x, point.y)) {
                    clickColumnObject = obj;
                    clickColumnObject.column = column;
                    clickColumnObject.obj = i;
                    break;
                  }
                }
                clickColumn = column;
              }
            });
          }

          if (clickColumnObject) {
            _setActiveObject({
              point: pointWindow,
              object: clickColumnObject,
            });
          } else if (clickColumn && !clickColumnObject) {
            _setActiveObject({
              point: pointWindow,
              object: clickColumn,
            });
          } else if (click_node && mode !== "union") {
            // Потом точки
            plan.dragNode = click_node;
            clickObject = {
              type: "node",
              object: click_node,
            };
            _setActiveObject({
              point,
              object: clickObject,
            });
            setUndo({ type: "plan" });
          } else if (plan.dragNode && mode !== "union") {
            if (plan.dragNode.isWall) {
              plan.setFloors("floor");
            } else if (plan.dragNode.isFigure) {
              plan.setFloors("figures");
            }
            setUndo({ type: "plan" });
          } else {
            if (activeObject && activeObject.isModule && rotate) {
              setUndo({
                type: "rotateModule",
                opt: {
                  obj: activeObject,
                  angle: activeObject.angle,
                  prev: undoModulePrev.angel,
                },
              });
              dispatch(projectState.setModal(""));
            } else if (activeObject && activeObject.isModule && moving) {
              setUndo({
                type: "movingModule",
                opt: {
                  obj: activeObject,
                  position: {
                    x: activeObject.position.x,
                    y: activeObject.position.y,
                  },
                  prev: {
                    x: undoModulePrev.position.x,
                    y: undoModulePrev.position.y,
                  },
                },
              });
              dispatch(projectState.setModal(""));
            }
            let clickObjectModule = null;
            if (filters.furniture) {
              props.modules.forEach((m) => {
                if (m.isHover(point)) clickObjectModule = m;
              });
            }
            const clickAngle = !clickObjectModule
              ? Object.entries(angles).find(([_, value]) => {
                  return (
                    Math.abs(pointWindow.x - value?.position?.x) < 20 &&
                    Math.abs(pointWindow.y - value?.position?.y) < 16
                  );
                })
              : null;

            let isAngle = false;

            if (clickObjectModule && !rotate) {
              // Верхний слой при нажатии - модули
              _setActiveObject({
                pointWindow,
                object: clickObjectModule,
              });
            } else if (clickAngle) {
              const [key, value] = clickAngle;

              let firstMainLink, secondMainLink;

              plan.bWalls.forEach((wall) => {
                if (wall.innerLink === value?.links?.[0])
                  firstMainLink = wall.mainLink;
                if (wall.innerLink === value?.links?.[1])
                  secondMainLink = wall.mainLink;
              });

              if (firstMainLink && secondMainLink) {
                const prevMainLink = prevActiveObject?.mainLink;

                const firstFree = plan.isFreeLink(firstMainLink);
                const secondFree = plan.isFreeLink(secondMainLink);

                let freeLink = firstFree || secondFree;

                if (
                  prevMainLink &&
                  firstFree &&
                  secondFree &&
                  (firstFree === prevMainLink || secondFree === prevMainLink)
                ) {
                  freeLink = prevMainLink;
                }

                if (freeLink) {
                  isAngle = true;

                  angles.current = key;
                  angles[key] = {
                    ...angles[key],
                    freeLink,
                    links: [firstMainLink, secondMainLink],
                  };
                  onChange({
                    object: angles[key],
                    value: value.angle,
                    position: value.position,
                  });
                  console.log(angles.current);
                }
              }
            }

            if (!clickObjectModule && !isAngle) {
              const linkByPoint = getLinkByPoint(
                getCanvasParams(),
                pointWindow
              );
              if (linkByPoint) {
                // Потом линии
                _setActiveObject({
                  point: pointWindow,
                  object: linkByPoint,
                });
              } else {
                const wallByPoint = getWallByPoint(
                  getCanvasParams(),
                  pointWindow
                );
                let clickWallObject = null;
                if (wallByPoint) {
                  if (wallByPoint?.isPolygonWall) {
                    for (let i = 0; i < wallByPoint.objects.length; i++) {
                      const obj = wallByPoint.objects[i];
                      if (
                        obj.wall &&
                        obj.wall.objects &&
                        polyPoint(obj.points, point.x, point.y)
                      ) {
                        clickWallObject = obj;
                        clickWallObject.wall = obj.wall;
                        clickWallObject.obj = obj.wall.objects.findIndex(
                          (o) => o === obj
                        );
                        _setActiveObject({
                          point: pointWindow,
                          object: clickWallObject,
                        });
                        break;
                      }
                    }
                  } else {
                    for (let i = 0; i < wallByPoint.objects.length; i++) {
                      const obj = wallByPoint.objects[i];
                      if (polyPoint(obj.points, point.x, point.y)) {
                        clickWallObject = obj;
                        clickWallObject.wall = wallByPoint;
                        clickWallObject.obj = i;
                        _setActiveObject({
                          point: pointWindow,
                          object: clickWallObject,
                        });
                        break;
                      }
                    }
                  }

                  if (!clickWallObject) {
                    _setActiveObject({
                      point: pointWindow,
                      object: wallByPoint,
                    });
                  }
                } else if (!columnSizing) {
                  const cycleFigureByPoint = getCycleByPoint(
                    getCanvasParams(),
                    pointWindow,
                    "figures"
                  );
                  if (cycleFigureByPoint) {
                    // Потом фигуры
                    _setActiveObject({
                      point: pointWindow,
                      object: cycleFigureByPoint.cycle,
                    });
                  } else {
                    const cycleFloorByPoint = getCycleByPoint(
                      getCanvasParams(),
                      pointWindow,
                      "floors"
                    );

                    if (zoom > 0.045) {
                      plan.cycles.forEach((cycle) => {
                        const diagonalCenter = Convert.toPixel(
                          cycle.diagonalCenter,
                          getCanvasParams()
                        );
                        if (!selectedText.diagonalCenter) {
                          selectedText.calculateBoundingBox(
                            cycle,
                            diagonalCenter,
                            pointWindow
                          );
                        }
                      });
                    }

                    if (selectedText.diagonalCenter) {
                      _setActiveObject({
                        point: pointWindow,
                        object: selectedText,
                      });
                      dispatch(projectState.setModal("info"));
                    }

                    if (cycleFloorByPoint && !selectedText.diagonalCenter) {
                      selectedRoom.getPoints(cycleFloorByPoint.cycle);

                      _setActiveObject({
                        point: pointWindow,
                        object: cycleFloorByPoint.cycle,
                      });

                      plan.setCycleActive(cycleFloorByPoint.index);
                    }
                  }
                }
              }
              angles.current = null;
              clearField();
            }
          }
        }
      }

      if (activeObject) {
        let target, point;
        if (event.type === "touchend") {
          if (event.changedTouches.length < 1) return;

          point = Convert.toSM(
            new Node(
              event.changedTouches[0].clientX,
              event.changedTouches[0].clientY
            ),
            getCanvasParams()
          );
          point.x = Math.round(point.x / 5) * 5;
          point.y = Math.round(point.y / 5) * 5;
        } else {
          point = Convert.toSM(
            new Node(event.clientX, event.clientY),
            getCanvasParams()
          );
        }
        if (activeObject._position) {
          target = { x: activeObject._position.x, y: activeObject._position.y };
        } else if (activeObject.diagonalCenter) {
          target = {
            x: activeObject.diagonalCenter.x,
            y: activeObject.diagonalCenter.y,
          };
        } else if (activeObject.isWall) {
          const x =
            activeObject.parallelLink.a.x -
            (activeObject.parallelLink.a.x - activeObject.innerLink.b.x) / 2;
          const y =
            activeObject.parallelLink.a.y -
            (activeObject.parallelLink.a.y - activeObject.innerLink.b.y) / 2;
          target = { x, y };
        } else if (activeObject.wall || activeObject.column) {
          target = {
            x:
              activeObject.rA1Inner.x +
              (activeObject.rB23d.x - activeObject.rA1Inner.x) / 2,
            y:
              activeObject.rA1Inner.y +
              (activeObject.rB23d.y - activeObject.rA1Inner.y) / 2,
          };
        }
        if (target) {
          const infoSize = 80;
          if (
            point.x <= target.x + infoSize &&
            point.x >= target.x - infoSize &&
            point.y <= target.y + infoSize &&
            point.y >= target.y - infoSize &&
            (activeObject?.objComment ||
              activeObject?.objTitle ||
              activeObject?.objImages?.length > 0)
          ) {
            dispatch(projectState.setModal("info"));
          }
        }
      }

      if (
        activeObject === prevActiveObject &&
        (cancelTouch ? true : !is_touch_device()) &&
        !columnSizing &&
        !rotate &&
        !isHoverColumn &&
        !isContextMenu &&
        !angles.current
      ) {
        if (activeObject?.isColumn || activeObject?.isModule) {
          activeObject.showSizeButtons = false;
        }
        _setActiveObject(null);
        activeObject = null;
        plan.setCycleActive(-1);
      }

      if (isHoverColumn || columnSizing) {
        plan.setFloors("floor");
      }

      if (!scaling) {
        plan.setFloorMaterials(
          plan.cycles.filter((cycle) => {
            if (
              plan.dragNode?.isControl &&
              cycle._links.some((link) => link?.controlA)
            )
              return true;
            return !cycle?.image;
          })
        );

        drag = false;
        moving = false;
        scaling = false;
        dragDistance = false;
        rotate = false;
        touchPrevEvent = null;
        clickMousePoint = null;
        startPosition = null;
        columnSizing = false;
        hoverObject = null;
        isHoverColumn = false;
        columnSizingPrev = 0;
        isContextMenu = false;

        if (!plan.virtualPolygonWall) {
          plan.virtualLink = null;
          plan.virtualNode = null;
          plan.dragNode = null;
          plan.removingZeroLengthLinks();
          plan.removingZeroLengthWalls();
          plan.clearEmptyNodes();
        }

        draw(ctx, plan);
      }
    };

    const handlerMove = (event) => {
      if (isWheelEvent) return;

      if (event.type === "touchmove") {
        event.preventDefault();
      }

      hoverObject = null;

      const touches = event.touches || event.changedTouches;
      if (scaling && touches?.length === 2) {
        distance =
          Math.round(
            Math.sqrt(
              (touches[0].clientX - touches[1].clientX) *
                (touches[0].clientX - touches[1].clientX) +
                (touches[0].clientY - touches[1].clientY) *
                  (touches[0].clientY - touches[1].clientY)
            ) / 5
          ) * 5;

        const scaleFactor = 0.005;
        if (lastDistance !== distance) {
          const zoomChange = (distance - lastDistance) * scaleFactor;
          zoom *= Math.exp(zoomChange);

          if (zoom < plan.maxZoom) zoom = plan.maxZoom;
          if (zoom > plan.minZoom) zoom = plan.minZoom;

          plan.zoom = zoom;
          lastDistance = distance;

          if (zoom >= plan.maxZoom && zoom <= plan.minZoom) {
            const mousePointAfterZoom = Convert.toSM(
              new Node(
                (touches[0].clientX + touches[1].clientX) / 2,
                (touches[0].clientY + touches[1].clientY) / 2
              ),
              getCanvasParams()
            );

            offsetX -= (mousePointAfterZoom.x - touchesZoom.x) * zoom;
            offsetY -= (mousePointAfterZoom.y - touchesZoom.y) * zoom;

            plan.offsetX = offsetX;
            plan.offsetY = offsetY;
          }
        }
        draw(ctx, plan);
        return;
      }

      let point;
      if (event.type === "touchmove" || event.type === "touchstart") {
        if (event.changedTouches.length < 1) return;
        point = Convert.toSM(
          new Node(
            event.changedTouches[0].clientX,
            event.changedTouches[0].clientY
          ),
          getCanvasParams()
        );
      } else {
        point = Convert.toSM(
          new Node(event.clientX, event.clientY),
          getCanvasParams()
        );
      }

      if (tool === "columns") {
        if (!plan.virtualColumn) {
          plan.virtualColumn = new Column(point);
        } else {
          plan.virtualColumn.x = point.x;
          plan.virtualColumn.y = point.y;
        }
        plan.virtualColumn.depth = columnParams.width;
        plan.virtualColumn.width = columnParams.depth;

        const cid = plan.columns.length;
        plan.virtualColumn.setParentWallID(-1);

        const stickPoint = stickColumnToWall(
          point,
          plan.bWalls,
          plan.virtualColumn,
          cid,
          zoom
        );

        if (stickPoint) {
          const { x, y, angle, wallIndex, wallSide } = stickPoint;

          plan.virtualColumn.x = x;
          plan.virtualColumn.y = y;
          plan.virtualColumn.angle = angle;

          plan.virtualColumn.setParentWallID(wallIndex, wallSide);
          plan.bWalls[wallIndex].setColumns(cid, wallSide);
        }

        draw(ctx, plan);
        return;
      }

      if (
        plan.dragNode &&
        (plan.mode === "polygonWalls" ||
          plan.mode === "walls" ||
          plan.mode === "ruler" ||
          plan.mode === "figures" ||
          plan.mode === "leader" ||
          plan.mode === "move")
      ) {
        let x = point.x,
          y = point.y;

        if (snap > 0) {
          if (mode === "union") {
            const snappedPoint = snapPointToLines(
              plan.bWalls.flatMap((w) => [
                w.innerLink,
                w.mainLink,
                w.parallelLink,
              ]),
              point,
              distForMerge()
            );

            x = snappedPoint.x;
            y = snappedPoint.y;
          } else {
            for (let i = 0; plan.nodes.length - 1 > i; i++) {
              if (plan.dragNode !== plan.nodes[i]) {
                const diffX = Math.abs(plan.nodes[i].x - point.x);
                const diffY = Math.abs(plan.nodes[i].y - point.y);
                if (
                  diffX <= distForMerge() &&
                  diffY < canvas.clientHeight / plan.zoom
                ) {
                  x = plan.nodes[i].x;
                }
                if (
                  diffY <= distForMerge() &&
                  diffX < canvas.clientWidth / plan.zoom
                ) {
                  y = plan.nodes[i].y;
                }
              }
            }

            plan.columns.forEach((column) => {
              column.points.forEach((columnPoint) => {
                const diffX = Math.abs(columnPoint.x - point.x);
                const diffY = Math.abs(columnPoint.y - point.y);
                if (
                  diffX <= distForMerge() &&
                  diffY < canvas.clientHeight / plan.zoom
                ) {
                  x = columnPoint.x;
                }
                if (
                  diffY <= distForMerge() &&
                  diffX < canvas.clientWidth / plan.zoom
                ) {
                  y = columnPoint.y;
                }
              });
            });

            x = Math.round(x / 5) * 5;
            y = Math.round(y / 5) * 5;
          }
        }

        //таскание точки тут
        plan.dragNode.x = x;
        plan.dragNode.y = y;
        if (plan.mode === "move" && mode !== "union") {
          if (
            currentWall &&
            (currentWall.leftCols.length || currentWall.rightCols.length)
          ) {
            const changeColumnParams = (cid) => {
              const col = plan.columns?.[cid];

              if (col) {
                const stickPoint = stickColumnToWall(
                  { x: col.x, y: col.y },
                  plan.bWalls.map((wall) =>
                    wall !== currentWall ? null : wall
                  ),
                  col,
                  cid,
                  zoom,
                  Infinity
                );

                if (stickPoint) {
                  const { x, y, angle, wallIndex, wallSide } = stickPoint;

                  col.x = x;
                  col.y = y;
                  col.angle = angle;

                  col.setParentWallID(wallIndex, wallSide);
                  plan.bWalls[wallIndex].setColumns(cid, wallSide);
                } else {
                  col.parentWallID = -1;
                }
              }
            };
            currentWall.leftCols.forEach(changeColumnParams);
            currentWall.rightCols.forEach(changeColumnParams);
          }

          plan.moveDragPairNode();
        }

        // Ищем ближайшие ноды для привязки
        const [_dist, _node] = plan.nodes.reduce(
          (dn, n) => {
            let _d;
            if (plan.dragNode !== n) _d = distNodeToNode(plan.dragNode, n);
            else _d = Infinity; // Отсеиваем ноду, которую тянем
            return _d < dn[0] ? [_d, n] : dn;
          },
          [Infinity, null]
        );
        if (_dist < distForMerge()) {
          plan.dragNode.x = _node.x;
          plan.dragNode.y = _node.y;
          if (plan.mode === "move") {
            plan.moveDragPairNode();
          }
        }

        if (plan.dragNode.isRuler) {
          const stickPoint = stickPointToWall(plan.dragNode, plan.bWalls, zoom);
          if (stickPoint) {
            const dist = distNodeToNode(plan.dragNode, {
              x: stickPoint.x,
              y: stickPoint.y,
            });
            if (dist < distForMerge()) {
              plan.dragNode.x = stickPoint.x;
              plan.dragNode.y = stickPoint.y;
            }
          }
        }

        if (plan.virtualLink) {
          const wall = plan.bWalls.filter(
            (w) => w.mainLink === plan.virtualLink
          )[0];
          if (wall) {
            _setActiveObject({
              point,
              object: wall,
            });
          }
        }
      } else if (plan.mode === "move" || plan.mode === "view") {
        // selectedRoom.move(plan, activeObject, point, pointWindow);
        if (rotate && activeObject?.showSizeButtons) {
          if (
            activeObject &&
            (activeObject.isModule || activeObject.isColumn) &&
            clickMousePoint &&
            mode !== "union"
          ) {
            activeObject.lookTo(point, clickMousePoint, startAngle);
            _setActiveObject({ point, object: activeObject });
            draw(ctx, plan);
            drag = false;
            return;
          }
        } else {
          if (
            activeObject &&
            activeObject.isModule &&
            clickMousePoint &&
            mode !== "union"
          ) {
            const vPos = clickMousePoint.clone().sub(point); //вектор перемещения
            if (startPosition === null) {
              startPosition = activeObject.position.clone();
            }
            activeObject.showSizeButtons = false;
            activeObject.position = {
              x: startPosition.x - vPos.x,
              y: startPosition.y - vPos.y,
            };
            draw(ctx, plan);
            drag = false;
            return;
          }
        }

        if (
          activeObject &&
          activeObject.isColumn &&
          columnSizing &&
          activeObject?.showSizeButtons
        ) {
          const column = activeObject;

          const isColumnHovered = checkHoverColumn(column, {
            x: point.x,
            y: point.y,
          });

          let objectPoint = new Vector2(column.x, column.y);

          let angelFix, angelFix1;
          if (
            columnSizingDirection === "top" ||
            columnSizingDirection === "bottom"
          ) {
            angelFix = 0;
            angelFix1 = 90 * (Math.PI / 180);
          } else if (
            columnSizingDirection === "right" ||
            columnSizingDirection === "left"
          ) {
            angelFix = 90 * (Math.PI / 180);
            angelFix1 = 0;
          }

          const pointA = new Vector2(point.x, point.y);
          const pointB = new Vector2(point.x, point.y);
          const v = new Vector2(point.x, point.y)
            .normalize()
            .rotateAround(
              new Vector2(0, 0),
              -column.angle - new Vector2(point.x, point.y).angle() - angelFix
            )
            .setLength(100000);

          pointA.x = pointA.x + v.x;
          pointA.y = pointA.y + v.y;
          pointB.x = pointB.x - v.x;
          pointB.y = pointB.y - v.y;

          const pointA1 = new Vector2(column.x, column.y);
          const pointB1 = new Vector2(column.x, column.y);
          const v2 = new Vector2(column.x, column.y)
            .normalize()
            .rotateAround(
              new Vector2(0, 0),
              -column.angle -
                new Vector2(column.x, column.y).angle() -
                angelFix1
            )
            .setLength(100000);

          pointA1.x = pointA1.x + v2.x;
          pointA1.y = pointA1.y + v2.y;
          pointB1.x = pointB1.x - v2.x;
          pointB1.y = pointB1.y - v2.y;

          const crossPoint = getIntersection(
            { x: pointA, y: pointB },
            { x: pointA1, y: pointB1 }
          );

          let columnSizingCurrent = objectPoint
            .clone()
            .sub(crossPoint)
            .length();

          let length = Math.round(columnSizingCurrent - columnSizingPrev);
          const v3 = pointA1
            .clone()
            .sub(pointB1)
            .setLength(length / 2);

          const isNotMinHeight = column.depth + length >= columnParams.minDepth;
          const isNotMinWidth = column.width + length >= columnParams.minWidth;

          let sizeLine = getParallelEdgeLine3(column, columnSizingDirection);
          const closestPoint = sizeLine.closestPointToPointParameter(
            new Vector3(point.x, point.y, 0),
            true
          );

          if (columnSizingPrev > 0 && closestPoint < 1) {
            if (columnSizingDirection === "top") {
              if (isNotMinHeight) {
                column.depth = column.depth + length;
                column.x = column.x + v3.x;
                column.y = column.y + v3.y;
              }
            } else if (columnSizingDirection === "bottom") {
              if (isNotMinHeight) {
                column.depth = column.depth + length;
                column.x = column.x - v3.x;
                column.y = column.y - v3.y;
              }
            } else if (columnSizingDirection === "right") {
              if (isNotMinWidth) {
                column.width = column.width + length;
                column.x = column.x + v3.x;
                column.y = column.y + v3.y;
              }
            } else if (columnSizingDirection === "left") {
              if (isNotMinWidth) {
                column.width = column.width + length;
                column.x = column.x - v3.x;
                column.y = column.y - v3.y;
              }
            }
          }
          if (closestPoint < 1 && !isColumnHovered) {
            objectPoint = new Vector2(column.x, column.y);
            columnSizingCurrent = objectPoint.clone().sub(crossPoint).length();

            columnSizingPrev = columnSizingCurrent;
          }

          _setActiveObject({ point, object: column });
        } else if (activeObject && activeObject.isColumn && isHoverColumn) {
          activeObject.x += point.x - startPosition.x;
          activeObject.y += point.y - startPosition.y;

          startPosition = point;

          activeObject.showSizeButtons = false;

          const cid = plan.columns.findIndex((el) => el === activeObject);
          activeObject.setParentWallID(-1);

          const stickPoint = stickColumnToWall(
            point,
            plan.bWalls,
            activeObject,
            cid,
            zoom
          );

          if (stickPoint) {
            const { x, y, angle, wallIndex, wallSide } = stickPoint;

            activeObject.x = x;
            activeObject.y = y;
            activeObject.angle = angle;
            activeObject.isFixed = false;

            activeObject.setParentWallID(wallIndex, wallSide);
            plan.bWalls[wallIndex].setColumns(cid, wallSide);
          }
        } else if (selectedRoom.ready2Move) {
          const point3 = new Vector3(point.x, point.y, 0);
          let distanceToWallAxis = Infinity;
          let magnetizationNode;

          plan.activeObject?.object?._links?.forEach((link) => {
            const mainWallLine = new Line3(
              new Vector3(link.a.x, link.a.y, 0),
              new Vector3(link.b.x, link.b.y, 0)
            );
            const target = new Vector3();
            mainWallLine.closestPointToPoint(point3, true, target);
            const distance = point3.distanceTo(target);
            if (distanceToWallAxis > distance) {
              distanceToWallAxis = point3.distanceTo(target);
              magnetizationNode = new Node(target.x, target.y);
            }
          });

          if (magnetizationNode) {
            plan.magnetization.nodeOnWall = magnetizationNode;
            plan.magnetization.nodeOnScreen = Convert.toPixel(
              magnetizationNode,
              getCanvasParams()
            );

            const closestWall = plan.getClosestWall(
              plan.bWalls,
              plan.magnetization.nodeOnWall,
              20,
              activeObject.links
            );

            // plan.magnetizationNode = pointWindow;
            if (
              event.type === "touchmove" &&
              event?.changedTouches &&
              touchPrevEvent?.changedTouches &&
              event.changedTouches.length > 0 &&
              touchPrevEvent.changedTouches.length > 0
            ) {
              if (closestWall.point) {
                let offset = { x: 0, y: 0 };
                if (plan.prevClosestWallPoint !== undefined) {
                  offset.x = closestWall.point.x - plan.prevClosestWallPoint.x;
                  offset.y = closestWall.point.y - plan.prevClosestWallPoint.y;
                } else {
                  selectedRoom.move(
                    plan,
                    modules,
                    activeObject,
                    closestWall.offset.x,
                    closestWall.offset.y
                  );
                }
                selectedRoom.move(
                  plan,
                  modules,
                  activeObject,
                  offset.x,
                  offset.y
                );
                plan.prevClosestWallPoint = closestWall.point;
                plan.magnetization.locked = true;
              } else {
                const touchOffset = {};
                if (prevTouch === null) {
                  touchOffset.x =
                    event.changedTouches[0].clientX -
                    touchPrevEvent.changedTouches[0].clientX;
                  touchOffset.y =
                    event.changedTouches[0].clientY -
                    touchPrevEvent.changedTouches[0].clientY;
                } else {
                  touchOffset.x =
                    event.changedTouches[0].clientX - prevTouch.clientX;
                  touchOffset.y =
                    event.changedTouches[0].clientY - prevTouch.clientY;
                }
                selectedRoom.move(
                  plan,
                  modules,
                  activeObject,
                  touchOffset.x / zoom,
                  touchOffset.y / zoom
                );
                prevTouch = event.changedTouches[0];
                plan.prevClosestWallPoint = undefined;
                plan.magnetization.locked = false;
              }
            } else {
              if (closestWall.point) {
                let offset = { x: 0, y: 0 };
                if (plan.prevClosestWallPoint !== undefined) {
                  offset.x = closestWall.point.x - plan.prevClosestWallPoint.x;
                  offset.y = closestWall.point.y - plan.prevClosestWallPoint.y;
                } else {
                  selectedRoom.move(
                    plan,
                    modules,
                    activeObject,
                    closestWall.offset.x,
                    closestWall.offset.y
                  );
                }
                selectedRoom.move(
                  plan,
                  modules,
                  activeObject,
                  offset.x,
                  offset.y
                );
                plan.prevClosestWallPoint = closestWall.point;
                plan.magnetization.locked = true;
              } else {
                selectedRoom.move(
                  plan,
                  modules,
                  activeObject,
                  event.movementX / zoom,
                  event.movementY / zoom
                );
                plan.prevClosestWallPoint = undefined;
                plan.magnetization.locked = false;
              }
            }
          }
        } else if (selectedText.diagonalCenter && selectedText.readyToMove) {
          if (
            event.type === "touchmove" &&
            event?.changedTouches &&
            touchPrevEvent?.changedTouches &&
            event.changedTouches.length > 0 &&
            touchPrevEvent.changedTouches.length > 0
          ) {
            const touchOffset = {};
            if (prevTouch === null) {
              touchOffset.x =
                event.changedTouches[0].clientX -
                touchPrevEvent.changedTouches[0].clientX;
              touchOffset.y =
                event.changedTouches[0].clientY -
                touchPrevEvent.changedTouches[0].clientY;
            } else {
              touchOffset.x =
                event.changedTouches[0].clientX - prevTouch.clientX;
              touchOffset.y =
                event.changedTouches[0].clientY - prevTouch.clientY;
            }
            selectedText.move(touchOffset.x / zoom, touchOffset.y / zoom);
            prevTouch = event.changedTouches[0];
          } else {
            selectedText.move(event.movementX / zoom, event.movementY / zoom);
          }
        } else {
          if (
            ImageBGData.isDragging &&
            ImageBGData.isEditing &&
            ImageBGData.mode === ""
          ) {
            ImageBGData.posX += event.movementX / zoom;
            ImageBGData.posY += event.movementY / zoom;
            // ↓↓↓ тут по клику перемещаем камеру
          } else if (event.type === "touchmove") {
            if (!scaling) {
              if (
                event?.changedTouches &&
                touchPrevEvent?.changedTouches &&
                event?.changedTouches?.length > 0 &&
                touchPrevEvent?.changedTouches?.length > 0
              ) {
                offsetX -=
                  event.changedTouches[0].clientX -
                  touchPrevEvent.changedTouches[0].clientX;
                offsetY -=
                  event.changedTouches[0].clientY -
                  touchPrevEvent.changedTouches[0].clientY;

                if (!dragDistance) {
                  dragDistance =
                    Math.abs(
                      event.changedTouches[0].clientX -
                        touchPrevEvent.changedTouches[0].clientX
                    ) > 10 ||
                    Math.abs(
                      event.changedTouches[0].clientY -
                        touchPrevEvent.changedTouches[0].clientY
                    ) > 10;
                }

                plan.offsetX = offsetX;
                plan.offsetY = offsetY;
                plan.canvasCenter = Convert.toSM(
                  Convert.getCanvasCenterPixel(getCanvasParams()),
                  getCanvasParams()
                );
              }

              touchPrevEvent = event;
            }
          } else if (drag) {
            offsetX -= event.movementX;
            offsetY -= event.movementY;

            plan.offsetX = offsetX;
            plan.offsetY = offsetY;
            plan.canvasCenter = Convert.toSM(
              Convert.getCanvasCenterPixel(getCanvasParams()),
              getCanvasParams()
            );
          }
        }
      }
      //hover ImageBG
      if (modal === "ImageBG") {
        if (clickObject && clickObject.type === "ImageBGpointA") {
          ImageBGData.points[0].x = point.x;
          ImageBGData.points[0].y = point.y;
        } else if (clickObject && clickObject.type === "ImageBGpointB") {
          ImageBGData.points[1].x = point.x;
          ImageBGData.points[1].y = point.y;
        } else if (clickObject && clickObject.type === "ImageBG") {
          ImageBGData.posX = point.x;
          ImageBGData.posY = point.y;
        }
        if (ImageBGData.mode !== "" && ImageBGData.points) {
          const pointA = ImageBGData.points[0];
          const pointB = ImageBGData.points[1];
          if (distNodeToNode(point, pointA) < 30) {
            hoverObject = { type: "ImageBGpointA", object: {} };
            draw(ctx, plan);
            return;
          } else if (distNodeToNode(point, pointB) < 30) {
            hoverObject = { type: "ImageBGpointB", object: {} };
            draw(ctx, plan);
            return;
          }
        } else {
          const center = Convert.toPixel(new Node(0, 0), getCanvasParams());
          const bg_width = ImageBGData.width * zoom * (ImageBGData.scale / 100);
          const bg_height =
            ImageBGData.width * zoom * (ImageBGData.scale / 100);
          const bg_PosX = center.x - bg_width / 2 + ImageBGData.posX * zoom;
          const bg_PosY = center.y - bg_height / 2 + ImageBGData.posY * zoom;
          const pivot = {
            x: bg_PosX + bg_width / 2,
            y: bg_PosY + bg_height / 2,
          };

          if (
            distNodeToNode(point, Convert.toSM(pivot, getCanvasParams())) < 24
          ) {
            hoverObject = { type: "ImageBG", object: {} };
            draw(ctx, plan);
            return;
          }
        }
      }
      draw(ctx, plan);
    };

    const handlerOut = (event) => {
      if (event.type === "touchcancel") event.preventDefault();
      draw(ctx, plan);
    };

    const handlerContext = (e) => {
      let point = null;

      if (is_touch_device()) {
        point = new Node(
          e.changedTouches[0].clientX,
          e.changedTouches[0].clientY
        );
      } else {
        point = new Node(e.clientX, e.clientY);
        e.preventDefault();
      }
      if (plan.mode === "view") return false;

      const isWall = getWallByPoint(getCanvasParams(), point, true);
      let isColumn = false;
      let isModule = props.modules.some((m) =>
        m.isHover(Convert.toSM(point, getCanvasParams()))
      );

      if (plan?.columns?.length) {
        isColumn = plan.columns.some((column) =>
          checkHoverColumn(column, Convert.toSM(point, getCanvasParams()))
        );
      }

      if (isWall || isColumn || isModule) {
        setAnchorPoint({ x: point.x + 5, y: point.y + 5 });
        setShowMenu(true);
        isContextMenu = true;
      }

      return false;
    };

    const handlerRedraw = (e) => {
      e.preventDefault();
      draw(ctx, plan);
      return false;
    };

    const handlerRedrawSimple = (e) => {
      dispatch(projectState.addPreloader());
      dispatch(projectState.decPreloader());
      e.preventDefault();
      draw(ctx, plan);
      return false;
    };

    const handlerPlanAnimation = (e) => {
      e.preventDefault();

      const {
        offsetX: oX,
        offsetY: oY,
        zoom: z,
      } = plan.getCommonPlanPosition();

      const startOffsetX = plan.offsetX;
      const startOffsetY = plan.offsetY;
      const startZoom = plan.zoom;

      const duration = 1000;
      let startTime;

      function animate(time) {
        if (!startTime) startTime = time;
        const timeElapsed = time - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        offsetX = startOffsetX + (oX - startOffsetX) * progress;
        offsetY = startOffsetY + (oY - startOffsetY) * progress;
        zoom = startZoom + (z - startZoom) * progress;

        draw(ctx, plan);

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }

      plan.offsetX = oX;
      plan.offsetY = oY;
      plan.zoom = z;

      requestAnimationFrame(animate);
    };

    const handlerCenterModule = (e) => {
      e.preventDefault();

      offsetX = canvas.width / 2 / ratio;
      offsetY = canvas.height / 2 / ratio;

      const position = Convert.toPixel(
        { x: e.obj._position.x, y: e.obj._position.y },
        getCanvasParams()
      );

      offsetX = position.x + 100 * ratio;
      offsetY = position.y;

      _setActiveObject({
        point: Convert.toSM(
          new Node(e.obj._position.x, e.obj._position.y),
          getCanvasParams()
        ),
        object: e.obj,
      });

      draw(ctx, plan);
      return false;
    };

    const handlerSelectObject = (e) => {
      e.preventDefault();

      const activeObject = e.obj;

      activeObject.wall = e.wall;
      activeObject.column = e.column;

      activeObject.obj = e.obj.id;

      _setActiveObject({
        point: {},
        object: activeObject,
      });

      draw(ctx, plan);
      return false;
    };

    const handlerUnselect = (e) => {
      if (activeObject?.showSizeButtons) {
        activeObject.showSizeButtons = false;
      }

      e.preventDefault();
      hoverObject = null;
      activeObject = null;
      _setActiveObject(null);

      drag = false;
      rotate = false;
      clickObject = null;
      plan.virtualNode = null;
      plan.virtualLink = null;

      draw(ctx, plan);
      return false;
    };

    const handlerReselect = (e) => {
      e.preventDefault();
      _setActiveObject(null);
      _setActiveObject({ point: {}, object: activeObject });

      draw(ctx, plan);
      return false;
    };

    let wheelTimeout = null;
    const handlerWheel = (e) => {
      e.preventDefault();
      clearTimeout(wheelTimeout);
      isWheelEvent = true;

      const scaleSpeed = 0.0005;
      const zoomChange = Math.exp(-e.deltaY * scaleSpeed);
      const newZoom = zoom * zoomChange;

      if (newZoom > plan.minZoom || newZoom < plan.maxZoom) return;

      zoom = newZoom;

      const mousePointBeforeZoom = Convert.toSM(
        new Node(e.clientX, e.clientY),
        { ...getCanvasParams(), zoom: zoom / zoomChange }
      );
      const mousePointAfterZoom = Convert.toSM(
        new Node(e.clientX, e.clientY),
        getCanvasParams()
      );

      offsetX -= (mousePointAfterZoom.x - mousePointBeforeZoom.x) * zoom;
      offsetY -= (mousePointAfterZoom.y - mousePointBeforeZoom.y) * zoom;

      plan.offsetX = offsetX;
      plan.offsetY = offsetY;

      plan.zoom = zoom;
      plan.canvasCenter = Convert.toSM(
        Convert.getCanvasCenterPixel(getCanvasParams()),
        getCanvasParams()
      );

      draw(ctx, plan);

      wheelTimeout = setTimeout(() => {
        isWheelEvent = false;
      }, 100);
    };

    const handlerResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let { devicePixelRatio: ratio = 1 } = window;
      ratio = ratio < 1 ? 2 - ratio : ratio;

      if (is_touch_device()) {
        offsetY -= (canvas.height - height * ratio) / 2;
      } else {
        offsetX = canvas.width / ratio / 2;
        offsetY = canvas.height / ratio / 2;
      }

      const isResize = canvas.width !== width || canvas.height !== height;

      if (isResize) {
        canvas.width = ctx.width = width * ratio;
        canvas.height = ctx.height = height * ratio;
        ctx.scale(ratio, ratio);

        canvasFake.width = ctxFake.width = width * ratio;
        canvasFake.height = ctxFake.height = height * ratio;
        ctxFake.scale(ratio, ratio);
      }

      plan.offsetX = offsetX;
      plan.offsetY = offsetY;
      plan.canvasCenter = Convert.toSM(
        Convert.getCanvasCenterPixel(getCanvasParams()),
        getCanvasParams()
      );

      draw(ctx, plan);
    };

    const { addEventListener, removeEventListener } = autoCanvasMoveEvent(
      getCanvasParams(),
      (changeX, changeY) => {
        plan.offsetX = offsetX += changeX;
        plan.offsetY = offsetY += changeY;
        draw(ctx, plan);
      },
      (event) => handlerMove(event)
    );

    if (is_touch_device()) {
      canvas.addEventListener("touchstart", handlerDown);
      canvas.addEventListener("touchend", handlerUp);
      canvas.addEventListener("touchmove", handlerMove);
      canvas.addEventListener("touchcancel", handlerOut);

      canvas.addEventListener("wheel", handlerWheel, { passive: false });
      canvas.addEventListener("redraw", handlerRedraw, false);
      canvas.addEventListener("planAnimation", handlerPlanAnimation, false);
      canvas.addEventListener("redrawSimple", handlerRedrawSimple, false);
      canvas.addEventListener("unselect", handlerUnselect, false);
      canvas.addEventListener("reselect", handlerReselect, false);
      window.addEventListener("resize", handlerResize, false);
    } else {
      addEventListener();

      canvas.addEventListener("mousedown", handlerDown);
      canvas.addEventListener("mouseup", handlerUp);
      canvas.addEventListener("mousemove", handlerMove);
      canvas.addEventListener("mouseout", handlerOut);

      canvas.addEventListener("wheel", handlerWheel, { passive: false });
      canvas.addEventListener("contextmenu", handlerContext, false);
      canvas.addEventListener("redraw", handlerRedraw, false);
      canvas.addEventListener("planAnimation", handlerPlanAnimation, false);
      canvas.addEventListener("redrawSimple", handlerRedrawSimple, false);
      canvas.addEventListener("unselect", handlerUnselect, false);
      canvas.addEventListener("reselect", handlerReselect, false);
      window.addEventListener("resize", handlerResize, false);
      window.addEventListener("keydown", handlerKeydown, false);
      window.addEventListener("keyup", handlerKeyUp, false);
    }

    canvas.addEventListener("centerModule", handlerCenterModule, false);
    canvas.addEventListener("selectObject", handlerSelectObject, false);

    document.body.addEventListener(
      "touchstart",
      function (e) {
        if (!showMenu) return null;
        if (e.target !== canvas && e.target !== contextMenu.current) {
          setShowMenu(false);
        }
      },
      false
    );

    draw(ctx, plan);

    if (plan.restored) {
      dispatch(ProjectState.setEditMode(false));
      setMoveTool();
      plan.restored = false;
    } else {
      plan.canvasCenter = Convert.toSM(
        Convert.getCanvasCenterPixel(getCanvasParams()),
        getCanvasParams()
      );
    }

    if (plan.activeObject) {
      _setActiveObject({
        point: {},
        object: plan.activeObject.object,
      });
    }

    return () => {
      if (is_touch_device()) {
        canvas.removeEventListener("touchstart", handlerDown);
        canvas.removeEventListener("touchend", handlerUp);
        canvas.removeEventListener("touchmove", handlerMove);
        canvas.removeEventListener("touchcancel", handlerOut);

        canvas.removeEventListener("wheel", handlerWheel, { passive: false });
        canvas.removeEventListener("redraw", handlerRedraw, false);
        canvas.removeEventListener(
          "planAnimation",
          handlerPlanAnimation,
          false
        );
        canvas.removeEventListener("redrawSimple", handlerRedrawSimple, false);
        canvas.removeEventListener("unselect", handlerUnselect, false);
        canvas.removeEventListener("reselect", handlerReselect, false);

        window.removeEventListener("resize", handlerResize, false);
      } else {
        removeEventListener();

        canvas.removeEventListener("mousedown", handlerDown);
        canvas.removeEventListener("mouseup", handlerUp);
        canvas.removeEventListener("mousemove", handlerMove);
        canvas.removeEventListener("mouseout", handlerOut);

        canvas.removeEventListener("wheel", handlerWheel, { passive: false });
        canvas.removeEventListener("contextmenu", handlerContext, false);
        canvas.removeEventListener("redraw", handlerRedraw, false);
        canvas.removeEventListener(
          "planAnimation",
          handlerPlanAnimation,
          false
        );
        canvas.removeEventListener("redrawSimple", handlerRedrawSimple, false);
        canvas.removeEventListener("unselect", handlerUnselect, false);
        canvas.removeEventListener("reselect", handlerReselect, false);

        window.removeEventListener("resize", handlerResize, false);
        window.removeEventListener("keydown", handlerKeydown, false);
        window.removeEventListener("keyup", handlerKeyUp, false);
      }
      canvas.removeEventListener("centerModule", handlerCenterModule, false);
      canvas.removeEventListener("selectObject", handlerSelectObject, false);
    };
  }, [
    props.modules,
    props.editMode,
    props.plan,
    props.plan.cycleActive,
    props.plan.undoArr,
    props.plan.redoArr,
    mode,
    cancelTouch,
    tool,
    snap,
    steps,
    ImageBGData,
    enableCreateWalls,
    showEstimated,
    level,
  ]);

  useEffect(() => {
    if (props.plan.nodes.length === 0 && !props.editMode) {
      dispatch(ProjectState.setEditMode(true));
      dispatch(ProjectState.setTool("move"));
      setActiveObject(null);
      props.plan.setActiveObject(null);
    }
    if (props.plan.cycles.length > 0 && props.editMode) {
      dispatch(ProjectState.setEditMode(false));
      dispatch(ProjectState.setTool("move"));
    }
  }, [props.plan]);

  useEffect(() => {
    if (index !== null) {
      setActiveObject({ point: {}, object: modules[index] });
      props.plan.setActiveObject(_activeObject);
      index = null;
    }
  }, [updateFlag]);

  useEffect(() => {
    if (modal === "ImageBG") {
      dispatch(projectState.setImageBG({ ...ImageBGData, isEditing: true }));
    } else {
      dispatch(projectState.setImageBG({ ...ImageBGData, isEditing: false }));
    }
  }, [modal]);

  const removeNode = (node) => {
    props.plan.removeNode(node);
    sendRedrawEvent(document.querySelector("#plan"));
    setActiveObject(null);
    props.plan.setActiveObject(null);
  };

  const handlerContextMenuClick = (value) => {
    if (!_activeObject) return;

    const { object } = _activeObject;
    const plan = props.plan;

    switch (value) {
      case "Union": {
        if (object?.isWall) {
          for (let i = 0; i < plan.bWalls.length; i++) {
            const commonPoint = getCommonPoint(
              plan.bWalls[i].mainLink,
              object.mainLink
            );
            const angle = calcAngleDeg(
              plan.bWalls[i].mainLink,
              object.mainLink
            );
            if (
              object.mainLink !== plan.bWalls[i].mainLink &&
              commonPoint &&
              (angle < 1 || (angle > 179 && angle < 181))
            ) {
              plan.extendWall(plan.bWalls[i], object.mainLink);
              plan.removeWall(object);
              break;
            }
          }
        }
        setActiveObject(null);
        plan.setActiveObject(null);
        sendUnselectEvent(document.querySelector("#plan"));
        break;
      }
      case "Resize": {
        if (object?.isColumn || object?.isModule) {
          object.showSizeButtons = true;
        }
        break;
      }
      case "PutOver": {
        const index = props.modules.findIndex(
          (e) => e.id === _activeObject.object.id
        );
        props.modules.splice(
          props.modules.length - 1,
          0,
          props.modules.splice(index, 1)[0]
        );
        break;
      }
      case "PutUnder": {
        const index = props.modules.findIndex(
          (e) => e.id === _activeObject.object.id
        );
        props.modules.splice(0, 0, props.modules.splice(index, 1)[0]);
        break;
      }
      case "AddPoint": {
        const canvas = ref.current;
        const ctx = canvas.getContext("2d");
        if (ctx && object?.isPolygonWall) {
          const point = Convert.toSM(new Node(anchorPoint.x, anchorPoint.y), {
            ctx,
            ...plan,
          });
          plan.addPointOnPolygonWall(object, point);
        }
        break;
      }
      default:
        break;
    }

    sendRedrawSimpleEvent(document.querySelector("#plan"));
    sendRedrawEvent(document.querySelector("#plan"));

    setShowMenu(false);
  };

  const modules = useSelector((store) => store.modules.modules);

  const changeModule = (module, variant) => {
    index = modules.indexOf(module);
    dispatch({ type: "CHANGE_MODULE", module, variant });
  };

  const styleImageBG = {
    opacity: ImageBGData.opacity / 100,
  };
  if (ImageBGData.dataUrl && filters.imageBG) {
    styleImageBG.backgroundImage = "url(" + ImageBGData.dataUrl + ")";
  }
  const styleMoveImageBGOverlay = {
    backgroundImage: `url("${window.confComponentUrl}assets/icons/movebg.svg")`,
    visibility:
      modal === "ImageBG" && ImageBGData.id && ImageBGData.mode === ""
        ? "visible"
        : "hidden",
  };

  const menuItems = useMemo(() => {
    if (_activeObject?.object?.isColumn) {
      return [{ value: "Resize", text: "Растянуть" }];
    } else if (_activeObject?.object?.isModule) {
      return [
        { value: "Resize", text: "Повернуть" },
        { value: "PutOver", text: "Поместить слой над всеми" },
        { value: "PutUnder", text: "Поместить слой под всеми" },
      ];
    } else if (_activeObject?.object?.isWall) {
      return [{ value: "Union", text: "Объединить стены" }];
    } else if (_activeObject?.object?.isPolygonWall) {
      return [{ value: "AddPoint", text: "Добавить точку" }];
    }
    return [];
  }, [_activeObject]);

  const objectInfo = useMemo(() => {
    if (_activeObject?.object?.diagonalCenter) {
      return props.plan.cycles.find(
        (cycle) =>
          cycle.diagonalCenter === _activeObject?.object?.diagonalCenter
      );
    }
    return _activeObject?.object;
  }, [_activeObject?.object, props.plan.cycles]);

  return (
    <>
      <canvas id="plan" ref={ref}></canvas>
      <canvas id="planFake" ref={refFake}></canvas>

      {modal === "ImageBG" && <ImageBG />}
      <div
        ref={refCanvasImageBG}
        className={"canvasImageBG"}
        style={styleImageBG}
      ></div>
      <div
        ref={refCanvasMoveImageBGOverlay}
        className={"canvasImageBGOverlay"}
        style={styleMoveImageBGOverlay}
      ></div>

      {object && (
        <EditField
          value={value}
          position={position}
          onChange={(value) => {
            onChange({ value });
          }}
          onSubmit={(value) => {
            if (object?.freeLink) {
              props.plan.changeAngle(
                object.freeLink,
                object.links,
                object.angle,
                value
              );
              sendRedrawSimpleEvent(document.querySelector("#plan"));
              clearField();
            }
          }}
        />
      )}

      <ContextMenu
        ref={contextMenu}
        list={menuItems}
        show={showMenu}
        anchorPoint={anchorPoint}
        onClick={(value) => handlerContextMenuClick(value)}
      />

      {_activeObject !== null &&
        _activeObject.object.isModule &&
        modal !== "estimateResult" && (
          <ModuleMenu module={_activeObject.object} />
        )}
      {modal === "moduleInfo" && _activeObject.object.isModule && (
        <ModuleInfo module={_activeObject.object} changeModule={changeModule} />
      )}
      {_activeObject !== null &&
        (_activeObject.object.wall || _activeObject.object.column) &&
        _activeObject.object.obj > -1 &&
        modal !== "estimateResult" && (
          <WallObjectInfo
            parent={_activeObject.object.wall || _activeObject.object.column}
            objIndex={_activeObject.object.obj}
          />
        )}
      {modal === "projects" && <Projects />}
      {_activeObject !== null &&
        _activeObject.object.type === "node" &&
        modal !== "estimateResult" && (
          <PointInfo node={_activeObject.object.object} />
        )}
      {_activeObject !== null &&
        _activeObject.object.isCycle &&
        (_activeObject.object.isFloor || _activeObject.object.isFigure) &&
        modal !== "estimateResult" && (
          <FloorInfo floor={_activeObject.object} />
        )}
      {_activeObject !== null &&
        _activeObject.object.isWall &&
        !_activeObject.object.isLink &&
        modal !== "estimateResult" && (
          <BWallInfo plan={props.plan} wall={_activeObject.object} />
        )}
      {_activeObject !== null &&
        _activeObject.object.isPolygonWall &&
        modal !== "estimateResult" && (
          <PolygonWallInfo plan={props.plan} wall={_activeObject.object} />
        )}
      {_activeObject !== null &&
        _activeObject?.object &&
        _activeObject?.object.isColumn &&
        modal !== "estimateResult" && (
          <ColumnInfo plan={props.plan} column={_activeObject.object} />
        )}
      {_activeObject !== null &&
        !_activeObject.object.isCycle &&
        (_activeObject.object.isRuler ||
          _activeObject.object.isFigure ||
          _activeObject.object.isLeader) &&
        modal !== "estimateResult" && (
          <LinkInfo plan={props.plan} link={_activeObject.object} />
        )}
      {_activeObject !== null &&
        _activeObject?.object &&
        modal === "estimate" && <EstimateEdit object={_activeObject.object} />}
      {objectInfo && modal === "info" && <ObjectInfo obj={objectInfo} />}
      {_activeObject !== null &&
        _activeObject.object &&
        modal === "estimateList" && (
          <EstimateObject object={_activeObject.object} />
        )}
      <EstimateFormatterMode canvas={ref.current} />
    </>
  );
};

const mapStateToProps = (state) => ({
  modules: state.modules.modules,
  editMode: state.project.editMode,
});

export default connect(mapStateToProps)(PlanEditor);
