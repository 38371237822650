import { Vector2 } from "three";

const tolerance = 0.001; // задайте вашу погрешность для точек
const angularTolerance = 0.000001; // задайте вашу погрешность для разнице углов стен

export function calculatePolygonWallsFunc() {
  const usedPolygonWalls = [];
  const connectedWalls = [];

  this.bWalls.forEach((bWall) => {
    if (bWall.connectedNodes.length) {
      bWall.isShow = bWall.ignoreAutoDisclosure ? bWall.isShow : true;
    }
    bWall.connectedNodes = [];
    bWall.objects.forEach((obj) => {
      delete obj.isHidden;
      delete obj.isConnected;
      delete obj.connected;
      delete obj.wall;
    });

    const mainNodes = [];
    const innerNodes = [];
    const touchedMainNodes = [];
    const touchedInnerNodes = [];
    const mainLinkVector = getVector2(bWall.mainLink.a, bWall.mainLink.b);

    this.polygonWalls.forEach((pWall) => {
      pWall.objects = [];
      pWall.nodes = pWall.nodes.filter((n) => n);

      for (let q = 0; q < pWall.links.length; q++) {
        const pWallLink = pWall.links[q];
        const nodes = [pWallLink.a, pWallLink.b];
        const pWallLinksVector = getVector2(nodes[0], nodes[1]);
        if (!isSameDirection(mainLinkVector, pWallLinksVector)) {
          continue;
        }
        for (const node of nodes) {
          if (
            isClose(bWall.mainLink.a.x, node.x, tolerance) &&
            isClose(bWall.mainLink.a.y, node.y, tolerance)
          ) {
            usedPolygonWalls.push(pWall);
            mainNodes.push(node);
          }
          if (
            isClose(bWall.mainLink.b.x, node.x, tolerance) &&
            isClose(bWall.mainLink.b.y, node.y, tolerance)
          ) {
            usedPolygonWalls.push(pWall);
            mainNodes.push(node);
            touchedMainNodes.push(bWall.mainLink.b);
          }
          if (
            isClose(bWall.innerLink.a.x, node.x, tolerance) &&
            isClose(bWall.innerLink.a.y, node.y, tolerance)
          ) {
            usedPolygonWalls.push(pWall);
            innerNodes.push(node);
            touchedInnerNodes.push(bWall.innerLink.a);
          }
          if (
            isClose(bWall.innerLink.b.x, node.x, tolerance) &&
            isClose(bWall.innerLink.b.y, node.y, tolerance)
          ) {
            usedPolygonWalls.push(pWall);
            innerNodes.push(node);
            touchedInnerNodes.push(bWall.innerLink.b);
          }
        }
      }
    });

    if (
      [...new Set(touchedMainNodes)].length === 2 ||
      [...new Set(touchedInnerNodes)].length === 2
    ) {
      bWall.connectedNodes.push(...mainNodes);
      bWall.connectedNodes.push(...innerNodes);
      bWall.isShow = bWall.ignoreAutoDisclosure ? bWall.isShow : false;
      connectedWalls.push(bWall);
    }
  });

  [...new Set(usedPolygonWalls)].forEach((pWall) => {
    const pairs = [];
    const excludedObjects = [];
    const allObjects = [];

    connectedWalls.forEach((wallA) => {
      wallA.objects.forEach((objectA) => {
        if (
          objectA.vector &&
          (objectA.isWindow || objectA.isHole || objectA.isDoor)
        ) {
          let nearestHole = null;
          let nearestWall = null;
          let minDistance = Infinity;

          connectedWalls.forEach((wallB) => {
            if (wallB !== wallA) {
              wallB.objects.forEach((objectB) => {
                if (
                  objectB.vector &&
                  (objectB.isWindow || objectB.isHole || objectB.isDoor)
                ) {
                  allObjects.push(objectB);

                  const middleA = new Vector2().lerpVectors(
                    objectA.objA,
                    objectA.objB,
                    0.5
                  );
                  const middleB = new Vector2().lerpVectors(
                    objectB.objA,
                    objectB.objB,
                    0.5
                  );

                  const distance = middleA.distanceTo(middleB);
                  if (distance < minDistance && distance < objectA.depth * 3) {
                    minDistance = distance;
                    nearestHole = objectB;
                    nearestWall = wallB;
                  }
                }
              });
            }
          });

          if (
            nearestHole &&
            objectA &&
            !(
              excludedObjects.includes(objectA) ||
              excludedObjects.includes(nearestHole)
            )
          ) {
            excludedObjects.push(objectA);
            excludedObjects.push(nearestHole);
            pairs.push({
              objectA,
              objectB: nearestHole,
              wallA: wallA,
              wallB: nearestWall,
              distance: minDistance,
              isReverse:
                wallA.mainLink?.lrBuild !== nearestWall.mainLink?.lrBuild,
            });
          }
        }
      });
    });

    allObjects.forEach((obj) => {
      if (!excludedObjects.includes(obj)) {
        pWall.objects.push(obj);
      }
    });

    pairs.forEach((pair) => {
      pair.objectA.wall = pair.wallA;
      pair.objectB.wall = pair.wallB;

      pair.objectA.isConnected = true;
      pair.objectB.isHidden = true;

      pair.objectA.connected = {
        object: pair.objectB,
        isReverse: pair.isReverse,
      };
      pair.objectB.connected = {
        object: pair.objectA,
        isReverse: pair.isReverse,
      };

      pWall.objects.push(pair.objectA);
      pWall.objects.push(pair.objectB);
    });
  });
}

function isClose(value1, value2, tolerance) {
  return Math.abs(value1 - value2) <= tolerance;
}

function isSameDirection(vector1, vector2) {
  const dotProduct = Math.abs(
    vector1.clone().normalize().dot(vector2.clone().normalize())
  );
  return Math.abs(dotProduct - 1) < angularTolerance;
}

function getVector2(nodeA, nodeB) {
  const Vector2PontA = new Vector2(nodeA.x, nodeA.y);
  const Vector2PontB = new Vector2(nodeB.x, nodeB.y);
  return Vector2PontB.clone().sub(Vector2PontA);
}
