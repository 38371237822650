/**
 * Проверяет стену на вхождение в комнату.
 * @param {wall} wall
 * @param {cycle[]} cycles
 * @returns возвращает комнату
 */
export const getWallCycle = (wall, cycles) => {
    for (let c = 0; cycles.length > c; c++) {
        for (let l = 0; cycles[c]._links.length > l; l++) {
            if (wall.mainLink === cycles[c]._links[l]) {
                return cycles[c];
            }
        }
    }
return null
}
