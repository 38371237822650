import { DxfWriter, LWPolylineFlags } from "@tarikjabiri/dxf";
import JSZip from "jszip";
import {
  BIG_DIM_SIZE,
  COLOR,
  DIM_DEC,
  DIM_SIZE,
  DIM_SIZE_80,
  OFFSET_LINK,
  drawColumns,
  drawInfo,
  drawModules,
  drawPaths,
  drawPolygonWalls,
  drawPolylines,
  drawSizes,
  drawText,
  fixDXF,
  info,
  saveFile,
  settingsDxf,
  splitText,
} from "./utils";

const setDxfParams = (dxf) => {
  dxf.document.dimStyleStandard.DIMTXT = DIM_SIZE_80;
  dxf.document.dimStyleStandard.DIMDEC = DIM_DEC;
  dxf.document.dimStyleStandard.DIMASZ = 30;
  dxf.addLType("dashed", "_ _ _ _ ", [40, -20, 40, -20, 40, -20, 40, -20]);
  return dxf;
};

export const exportDxf = async (plan, modules, token = "") => {
  const { bWalls: walls, polygonWalls, links, cycles, columns } = plan;

  info.reset();

  const zip = new JSZip();
  const dxfWriter = new DxfWriter();

  const dxf = setDxfParams(dxfWriter);

  const planPosition = plan.getCommonPlanPosition();

  const roomWalls = [];
  cycles.forEach((cycle) => {
    if (settingsDxf.showAreaFigures) {
      drawText(dxf, cycle.diagonalCenter, cycle.squareText);
    } else if (!cycle?.isFigure) {
      drawText(dxf, cycle.diagonalCenter, cycle.squareText);
    }

    if (cycle?.isFigure) {
      if (cycle?.height) {
        drawText(
          dxf,
          {
            x: cycle.diagonalCenter.x,
            y: cycle.diagonalCenter.y + BIG_DIM_SIZE,
          },
          `H=${cycle.height} мм`
        );
      }
      if (cycle?.heightFromFloor) {
        drawText(
          dxf,
          {
            x: cycle.diagonalCenter.x,
            y: cycle.diagonalCenter.y + BIG_DIM_SIZE * 2,
          },
          `h=${cycle.heightFromFloor} мм`
        );
      }
    } else {
      drawText(
        dxf,
        { x: cycle.diagonalCenter.x, y: cycle.diagonalCenter.y - BIG_DIM_SIZE },
        `H=${[...new Set(cycle.links.map((link) => link.height))].join(",")} мм`
      );
    }

    if (cycle.objTitle) {
      drawText(
        dxf,
        { x: cycle.diagonalCenter.x, y: cycle.diagonalCenter.y + BIG_DIM_SIZE },
        cycle.objTitle
      );
    }
    if (cycle.isFloor) {
      const points = cycle._points
        .slice(1, cycle._points.length - 1)
        .concat(cycle._points[0]);
      drawPolylines(dxf, { flags: LWPolylineFlags.Closed }, points);
    }
  });

  drawPolygonWalls(dxf, polygonWalls);

  walls.forEach((wall) => {
    drawPaths(dxf, [wall.innerLink.a, wall.innerLink.b], walls);
    wall.wallSizes.forEach((size) => {
      drawSizes(dxf, size.a, size.b, {
        isReverse: wall.mainLink.lrBuild === "right",
        trueColor: COLOR.GRAY,
      });
    });
  });

  links.forEach((link) => {
    if (link.isRuler) {
      drawSizes(dxf, link.a, link.b, { offset: 0, trueColor: COLOR.ORANGE });
    }
    if (link.isFigure) {
      drawPolylines(dxf, { lineType: "dashed" }, [link.a, link.b]);
      drawSizes(dxf, link.a, link.b, { trueColor: COLOR.GRAY });
    }
    if (link?.isLeader) {
      const leaderLength = 1000;
      const leader = { x: link.b.x + leaderLength, y: link.b.y };
      drawPolylines(dxf, {}, [link.a, link.b, leader]);

      const position = {
        x: link.b.x + leaderLength / 2,
        y: link.b.y - DIM_SIZE,
      };

      let offset = 0;
      splitText(link.leaderText).forEach((text) => {
        drawText(dxf, { x: position.x, y: position.y - offset }, text, {
          size: DIM_SIZE,
        });
        offset += OFFSET_LINK;
      });
    }
  });

  drawColumns(dxf, columns, walls);

  const modulesDXF = await drawModules(dxf, plan, modules);
  const infoDXF = await drawInfo(dxf, planPosition);
  const dxfString = fixDXF(dxf.stringify(), [...modulesDXF, ...infoDXF]);

  [...modulesDXF, ...infoDXF].forEach((module) => {
    zip.file(`${module.name}.png`, module.blob, { base64: true });
  });
  zip.file(`${token}.dxf`, dxfString);
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveFile(content, `${token}.zip`);
  });
};
