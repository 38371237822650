export const wallConstants = {
  optionsFillWall: {
    //hatch - пока отменили, нет разделения по штриховкам
    concrete: {
      sort: 1,
      id: "concrete",
      label: "Бетон",
      hatch: "blackFill",
      color: {
        arbg: { a: 1, r: 91, g: 91, b: 91 },
      },
    },
    brick: {
      id: "brick",
      label: "Кирпич",
      hatch: "hatchDraw",
      color: {
        arbg: { a: 1, r: 255, g: 127, b: 127 },
      },
    },
    drywall: {
      id: "drywall",
      label: "Гипсокартон",
      hatch: "beigeFill",
      color: {
        arbg: { a: 1, r: 127, g: 255, b: 127 },
      },
    },
    general: {
      id: "general",
      label: "Общий",
      hatch: "greyFill",
      color: {
        arbg: { a: 1, r: 127, g: 127, b: 255 },
      },
    },
    block: {
      id: "block",
      label: "Блок",
      hatch: "pointsDraw",
      color: {
        arbg: { a: 1, r: 214, g: 214, b: 214 },
      },
    },
  },
};
