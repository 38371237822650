import { LWPolylineFlags, TrueColor } from "@tarikjabiri/dxf";
import * as martinez from "martinez-polygon-clipping";
import { getWallColor } from "./getWallColor";
import { drawObject } from "./index";
import { drawHatches, drawPolylines } from "./primitives";

export const drawPolygonWalls = (dxf, polygonWalls) => {
  polygonWalls.forEach((wall) => {
    const shownObjects = wall.objects.filter((obj) => !obj?.isHidden);
    const wallColor = getWallColor(wall);
    const trueColorWallColor = TrueColor.fromRGB(
      wallColor.r,
      wallColor.g,
      wallColor.b
    );
    if (shownObjects?.length !== 0) {
      const convertVector2ArrayToPolygon = (vectorArray) => {
        return vectorArray.map((vector) => [vector.x, vector.y]);
      };

      const mainPolygon = [
        convertVector2ArrayToPolygon([...wall.nodes, wall.nodes[0]]),
      ];
      const polygonsToCut = [
        shownObjects.map((obj) =>
          convertVector2ArrayToPolygon([...obj.points, obj.points[0]])
        ),
      ];

      const subtractPolygons = (mainPolygon, polygonsToCut) => {
        let resultPolygon = mainPolygon;
        let prev = null;

        polygonsToCut.forEach((polygon) => {
          if (!prev) {
            prev = polygon;
          } else {
            prev = martinez.union(prev, polygon);
          }
        });

        return martinez.diff(resultPolygon, prev);
      };

      const result = subtractPolygons(mainPolygon, polygonsToCut);
      const resultVector2 = result.flatMap((ringGroup) =>
        ringGroup.map((ring) =>
          ring.map((point) => ({ x: point[0], y: point[1] }))
        )
      );

      resultVector2.forEach((points) => {
        drawPolylines(dxf, { flags: LWPolylineFlags.None }, points);
        drawHatches(dxf, { trueColor: trueColorWallColor }, points);
      });

      shownObjects.forEach((obj) => {
        drawObject(dxf, obj, wall, false);
      });
    } else {
      drawPolylines(dxf, { flags: LWPolylineFlags.None }, wall.nodes);
      drawHatches(dxf, { trueColor: trueColorWallColor }, wall.nodes);
    }
  });
};
