import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsState as ProjectStore } from '../../../Redux/project';
import ModalWrapper from '../../ModalWrapper';

export const Alert = () => {
    const dispatch = useDispatch();
    const message = useSelector((store) => store.project.alert);

    return (
        <div>
            <div
                className='modal-bg'
                onClick={() => dispatch(ProjectStore.setAlert(null))}>
            </div>
            <div className='modal-info'>
                <ModalWrapper
                    title={""}
                    onClose={() => dispatch(ProjectStore.setAlert(null))}
                >
                    <div className={"modal-info-block"}>{message}</div>
                </ModalWrapper>
            </div>
        </div>
    );
}
