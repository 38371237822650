const getEdgeScrollFactor = (pos, minBoundary, maxBoundary, margin) => {
    if (pos < minBoundary) {
        return -(Math.min(1, (minBoundary - pos) / margin));
    }
    if (pos > maxBoundary) {
        return Math.min(1, (pos - maxBoundary) / margin);
    }
    return 0;
}

export const autoCanvasMoveEvent = (params, onCanvasUpdate, onMouseMove) => {
    const { canvas, ratio, mode, plan } = params;

    let lastEvent = null;
    let isMouseInsideBoundary = true;

    const margin = 50;
    const header = document.querySelector('.conf-header');
    const topBoundary = header?.offsetHeight + margin;
    const screenWidth = canvas.width / ratio;
    const screenHeight = canvas.height / ratio;

    let currentOffsetX = 0;
    let currentOffsetY = 0;
    const smoothFactor = 0.1;

    const updateOffset = () => {
        if (!lastEvent) return;

        const scrollFactorX = getEdgeScrollFactor(lastEvent.clientX, margin, screenWidth - margin, margin);
        const scrollFactorY = getEdgeScrollFactor(lastEvent.clientY, topBoundary, screenHeight - margin, margin);

        const speed = 10;
        const targetOffsetX = speed * scrollFactorX;
        const targetOffsetY = speed * scrollFactorY;

        if (scrollFactorX !== 0 || scrollFactorY !== 0) {
            isMouseInsideBoundary = false;
            currentOffsetX += (targetOffsetX - currentOffsetX) * smoothFactor;
            currentOffsetY += (targetOffsetY - currentOffsetY) * smoothFactor;
            onCanvasUpdate(currentOffsetX, currentOffsetY);
        } else {
            isMouseInsideBoundary = true;
            currentOffsetX += (0 - currentOffsetX) * smoothFactor;
            currentOffsetY += (0 - currentOffsetY) * smoothFactor;
            if (Math.abs(currentOffsetX) < 0.01 && Math.abs(currentOffsetY) < 0.01) {
                currentOffsetX = 0;
                currentOffsetY = 0;
            }
            if (currentOffsetX !== 0 || currentOffsetY !== 0) {
                onCanvasUpdate(currentOffsetX, currentOffsetY);
            }
        }
    }

    const onEdgeMovement = (event) => {
        if ((plan.virtualPolygonWall || plan.dragNode || plan.virtualNode) && mode === 'union') {
            lastEvent = event;
            updateOffset();
        } else {
            lastEvent = null;
        }
    };

    let moveAnimationId = null;
    const animate = () => {
        if (lastEvent && !isMouseInsideBoundary) {
            updateOffset();
            onMouseMove(lastEvent);
        }
        moveAnimationId = requestAnimationFrame(animate)
    };

    animate();

    const events = {
        addEventListener: () => {
            window.addEventListener('mousemove', onEdgeMovement);
        },
        removeEventListener: () => {
            cancelAnimationFrame(moveAnimationId);
            window.removeEventListener('mousemove', onEdgeMovement);
        }
    };

    if (mode === 'union') {
        return events;
    }

    events.removeEventListener();
    return { addEventListener: () => {}, removeEventListener: () => {} };
}
